import { CallAPIAction, Status } from 'api/types'
import Actions from 'redux/actions'

type State = {
  error: string
} & Status

const initialState = {
  isLoading: false,
  success: false,
  fail: false,
  error: '',
}

export default (
  state: State = initialState,
  action: CallAPIAction<
    Actions.SUBMIT_OFFLINE_PAYMENT_ASYNC,
    Actions.SUBMIT_OFFLINE_PAYMENT_SUCCESS,
    Actions.SUBMIT_OFFLINE_PAYMENT_FAIL,
    {},
    {
      error: string
    }
  >,
): State => {
  switch (action.type) {
    case Actions.SUBMIT_OFFLINE_PAYMENT_ASYNC:
    case Actions.SUBMIT_OFFLINE_PAYMENT_SUCCESS: {
      return {
        ...state,
        ...action.status,
      }
    }

    case Actions.SUBMIT_OFFLINE_PAYMENT_FAIL: {
      return {
        ...state,
        ...action.status,
        error: action.payload?.error || '',
      }
    }

    default:
      return state
  }
}
