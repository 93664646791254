import { ListingCVRequest, ListingCVRequestFromBE, ListingRequestStatus } from 'common/types/commonTypes'

export const getEmptyListingCVRequest = () => ({
  id: 0,
  editId: 0,
  birthDate: '',
  city: {
    id: 0,
    label: '',
    labelEn: '',
  },
  createdAt: '',
  employer: '',
  employerMobile: '',
  employerCity: '',
  experienceInSaudi: 0,
  gender: '',
  isMale: false,
  iqamaNumber: '',
  laborerMobile: '',
  martialStatus: '',
  name: {
    id: 0,
    label: '',
    labelEn: '',
  },
  qualification: '',
  religion: '',
  salary: 0,
  status: ListingRequestStatus.EXPIRED,
  editStatus: ListingRequestStatus.EXPIRED,
  profileImage: '',
  occupation: {
    id: 0,
    label: '',
    labelEn: '',
  },
  nationality: {
    id: 0,
    label: '',
    labelEn: '',
  },
  serviceCost: 0,
  skills: [],
  expireAt: '',
  approvalAt: '',
})

export const convertListingCVRequestToState = (item: ListingCVRequestFromBE): ListingCVRequest => {
  const isEdit =
    !!item.editing_requests?.length &&
    item.status === ListingRequestStatus.PENDING_EDIT_REQUEST &&
    item.editing_requests[0].status === ListingRequestStatus.WAITING_LABORER_APPROVAL

  const editRequest = item.editing_requests?.[item.editing_requests?.length - 1]

  return {
    id: item.id,
    editId: editRequest?.id || 0,
    approvalAt: isEdit ? editRequest?.approval_at : null,
    occupation: {
      id: 0,
      label: item.occupation.label,
      labelEn: item.occupation.label_en,
    },
    employer: item.employer_name || '',
    status: item.status,
    editStatus: editRequest?.status,
    birthDate: item.birth_date,
    experienceInSaudi: isEdit ? editRequest?.years_of_experience_ksa : item.experience_in_saudi,
    gender: item.gender,
    isMale: item.gender === 'ذكر',
    iqamaNumber: item.iqama_number,
    profileImage: isEdit ? editRequest?.laborer_profile : item.laborer_profile,
    martialStatus: item.martial_status,
    name: {
      id: 0,
      label: item.name,
      labelEn: item.name_en,
    },
    city: {
      id: item?.city.evisa_id || 0,
      label: isEdit ? editRequest?.laborer_city.label_ar : item.city.label_ar,
      labelEn: isEdit ? editRequest?.laborer_city.label_en : item.city.label_en,
    },
    nationality: {
      id: 0,
      label: item.nationality.label,
      labelEn: item.nationality.label_en,
    },
    qualification: isEdit ? editRequest?.educational_qualification : item.qualification,
    religion: item.religion,
    salary: isEdit ? editRequest?.monthly_salary : item.salary,
    employerMobile: item.employer_mobile || '',
    employerCity: item.employer_city || '',
    createdAt: isEdit ? editRequest?.created_at : item.created_at,
    laborerMobile: isEdit ? editRequest?.laborer_mobile_number : item.laborer_mobile,
    serviceCost: item.transfer_service_cost,
    skills: (isEdit ? editRequest?.skills : item.skills)
      ? (isEdit ? editRequest?.skills : item.skills).map((skill) => ({
          id: skill.evisa_id,
          label: skill.label_ar,
          labelEn: skill.label_en,
        }))
      : [],
    expireAt: (isEdit ? editRequest?.expire_at : item.expire_at) || '',
  }
}

export const getStylesForListingRequest = (status: ListingRequestStatus) => {
  switch (status) {
    case ListingRequestStatus.WAITING_LABORER_APPROVAL:
    case ListingRequestStatus.WAITING_EMPLOYER_CONFIRMATION:
    case ListingRequestStatus.WAITING_APPROVAL:
    case ListingRequestStatus.PENDING_EDIT_REQUEST:
    case ListingRequestStatus.EXPIRED_LABORER_APPROVAL:
    case ListingRequestStatus.EXPIRED:
      return 'warning'
    case ListingRequestStatus.ACTIVE:
    case ListingRequestStatus.SELECTED:
    case ListingRequestStatus.TRANSFERRED:
    case ListingRequestStatus.APPROVED:
      return 'success'
    case ListingRequestStatus.REJECTED_LABORER_APPROVAL:
    case ListingRequestStatus.CANCELLED_EMPLOYER:
    case ListingRequestStatus.DEACTIVATED_EMPLOYER:
    case ListingRequestStatus.REJECTED_TRANSFER:
    case ListingRequestStatus.REJECTED:
      return 'danger'
    default:
      return ''
  }
}
