import { takeLatest } from 'redux-saga/effects'

import {
  getListingRequests as getListingRequestsAction,
  acceptListingRequest as acceptListingRequestAction,
  rejectListingRequest as rejectListingRequestAction,
  acceptEditListingRequest as acceptEditListingRequestAction,
  rejectEditListingRequest as rejectEditListingRequestAction,
} from 'redux/actionCreators/laborerPortalAPI/marketplace'
import laborerPortalAPI from 'api/laborerPortalAPI/marketplace'
import Actions from 'redux/actions'
import { getSimpleRequestHandler } from 'saga/utils'

const getListingRequests = getSimpleRequestHandler<
  typeof laborerPortalAPI.getListingRequests,
  ReturnType<typeof getListingRequestsAction>
>({
  actionLoading: Actions.GET_LABORER_LISTING_REQUESTS_ASYNC,
  actionSuccess: Actions.GET_LABORER_LISTING_REQUESTS_SUCCESS,
  actionFail: Actions.GET_LABORER_LISTING_REQUESTS_FAIL,
  callApiFn: laborerPortalAPI.getListingRequests,
})

const acceptListingRequest = getSimpleRequestHandler<
  typeof laborerPortalAPI.acceptListingRequest,
  ReturnType<typeof acceptListingRequestAction>
>({
  actionLoading: Actions.ACCEPT_LABORER_LISTING_REQUEST_ASYNC,
  actionSuccess: Actions.ACCEPT_LABORER_LISTING_REQUEST_SUCCESS,
  actionFail: Actions.ACCEPT_LABORER_LISTING_REQUEST_FAIL,
  callApiFn: laborerPortalAPI.acceptListingRequest,
})

const rejectListingRequest = getSimpleRequestHandler<
  typeof laborerPortalAPI.rejectListingRequest,
  ReturnType<typeof rejectListingRequestAction>
>({
  actionLoading: Actions.REJECT_LABORER_LISTING_REQUEST_ASYNC,
  actionSuccess: Actions.REJECT_LABORER_LISTING_REQUEST_SUCCESS,
  actionFail: Actions.REJECT_LABORER_LISTING_REQUEST_FAIL,
  callApiFn: laborerPortalAPI.rejectListingRequest,
})

const acceptEditListingRequest = getSimpleRequestHandler<
  typeof laborerPortalAPI.acceptEditListingRequest,
  ReturnType<typeof acceptEditListingRequestAction>
>({
  actionLoading: Actions.ACCEPT_LABORER_EDIT_LISTING_REQUEST_ASYNC,
  actionSuccess: Actions.ACCEPT_LABORER_EDIT_LISTING_REQUEST_SUCCESS,
  actionFail: Actions.ACCEPT_LABORER_EDIT_LISTING_REQUEST_FAIL,
  callApiFn: laborerPortalAPI.acceptEditListingRequest,
})

const rejectEditListingRequest = getSimpleRequestHandler<
  typeof laborerPortalAPI.rejectEditListingRequest,
  ReturnType<typeof rejectEditListingRequestAction>
>({
  actionLoading: Actions.REJECT_LABORER_EDIT_LISTING_REQUEST_ASYNC,
  actionSuccess: Actions.REJECT_LABORER_EDIT_LISTING_REQUEST_SUCCESS,
  actionFail: Actions.REJECT_LABORER_EDIT_LISTING_REQUEST_FAIL,
  callApiFn: laborerPortalAPI.rejectEditListingRequest,
})

export default [
  takeLatest(Actions.GET_LABORER_LISTING_REQUESTS, getListingRequests),
  takeLatest(Actions.ACCEPT_LABORER_LISTING_REQUEST, acceptListingRequest),
  takeLatest(Actions.REJECT_LABORER_LISTING_REQUEST, rejectListingRequest),
  takeLatest(Actions.ACCEPT_LABORER_EDIT_LISTING_REQUEST, acceptEditListingRequest),
  takeLatest(Actions.REJECT_LABORER_EDIT_LISTING_REQUEST, rejectEditListingRequest),
]
