import { CheckListLaborerEligibilityFailResponse, CheckListLaborerEligibilityResponse } from 'api/contractAuthAPI'
import { Status, CallAPIAction } from 'api/types'
import { ObjectWithTranslation } from 'common/types/transformedRespTypes'
import Actions from 'redux/actions'

type State = {
  occupationId: string
  idNumber: string
  name: ObjectWithTranslation
  occupation: ObjectWithTranslation
  nationality: ObjectWithTranslation
  message?: string
} & Status

const initialState = {
  message: '',
  idNumber: '',
  occupationId: '',
  name: {
    id: 0,
    label: '',
    labelEn: '',
  },
  occupation: {
    id: 0,
    label: '',
    labelEn: '',
  },
  nationality: {
    id: 0,
    label: '',
    labelEn: '',
  },
  isLoading: false,
  success: false,
  fail: false,
}

export default (
  state: State = initialState,
  action: CallAPIAction<
    Actions.CHECK_LIST_LABORER_ELIGIBILITY_ASYNC,
    Actions.CHECK_LIST_LABORER_ELIGIBILITY_SUCCESS,
    Actions.CHECK_LIST_LABORER_ELIGIBILITY_FAIL,
    CheckListLaborerEligibilityResponse,
    CheckListLaborerEligibilityFailResponse
  >,
): State => {
  switch (action.type) {
    case Actions.CHECK_LIST_LABORER_ELIGIBILITY_ASYNC: {
      return {
        ...state,
        ...action.status,
      }
    }

    case Actions.CHECK_LIST_LABORER_ELIGIBILITY_SUCCESS: {
      return {
        ...state,
        ...action.status,
        occupationId: action.payload.occupationEvisaData?.id || '',
        idNumber: action.payload.idNumber,
        name: {
          id: 0,
          label: action.payload.name,
          labelEn: action.payload.nameEn,
        },
        occupation: {
          id: 0,
          label: action.payload.occupation.label,
          labelEn: action.payload.occupation.labelEn,
        },
        nationality: {
          id: 0,
          label: action.payload.nationality.label,
          labelEn: action.payload.nationality.labelEn,
        },
      }
    }
    case Actions.CHECK_LIST_LABORER_ELIGIBILITY_FAIL: {
      return {
        ...state,
        ...action.status,
        message: action.payload?.message || action.payload?.error?.message,
      }
    }
    default:
      return state
  }
}
