import { CallAPIAction } from 'api/types'
import { HIDE_HOME_KABI_SERVICE_BAR, HIDE_MAAROFA_KABI_SERVICE_BAR } from 'common/constants'
import Actions from 'redux/actions'

type State = {
  isHomeBarHidden: boolean
  isMaarofaBarHidden: boolean
}

const initialState: State = {
  isHomeBarHidden: !!localStorage.getItem(HIDE_HOME_KABI_SERVICE_BAR),
  isMaarofaBarHidden: !!localStorage.getItem(HIDE_MAAROFA_KABI_SERVICE_BAR),
}

export default (
  state: State = initialState,
  action: CallAPIAction<Actions.CLOSE_KABI_HOME_BAR, Actions.CLOSE_KABI_MAAROFA_BAR>,
): State => {
  switch (action.type) {
    case Actions.CLOSE_KABI_HOME_BAR: {
      localStorage.setItem(HIDE_HOME_KABI_SERVICE_BAR, 'true')

      return {
        ...state,
        isHomeBarHidden: true,
      }
    }
    case Actions.CLOSE_KABI_MAAROFA_BAR: {
      localStorage.setItem(HIDE_MAAROFA_KABI_SERVICE_BAR, 'true')

      return {
        ...state,
        isMaarofaBarHidden: true,
      }
    }

    default:
      return state
  }
}
