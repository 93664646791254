import Actions from 'redux/actions'
import type { CallAPIAction, Status } from 'api/types'
import { CreateInterviewResponse } from 'api/interviewAPI'

type State = {
  bill: {
    amount: number
    breakdown: {
      servicePrice: number
      total: number
      vat: number
    }
    createdAt: string
    id: number
    status: string
    uuid: string
  }
  createdAt: string
  displayId: number
  status: string
} & Status

const initialState = {
  bill: {
    amount: 0,
    breakdown: {
      servicePrice: 0,
      total: 0,
      vat: 0,
    },
    createdAt: '',
    id: 0,
    status: '',
    uuid: '',
  },
  createdAt: '',
  displayId: 0,
  status: '',
  isLoading: false,
  success: false,
  fail: false,
}

export default (
  state: State = initialState,
  action: CallAPIAction<
    Actions.CREATE_LABORER_INTERVIEW_ASYNC,
    Actions.CREATE_LABORER_INTERVIEW_SUCCESS,
    Actions.CREATE_LABORER_INTERVIEW_FAIL,
    CreateInterviewResponse,
    {}
  >,
): State => {
  switch (action.type) {
    case Actions.CREATE_LABORER_INTERVIEW_ASYNC:
    case Actions.CREATE_LABORER_INTERVIEW_FAIL: {
      return {
        ...state,
        ...action.status,
      }
    }

    case Actions.CREATE_LABORER_INTERVIEW_SUCCESS: {
      return {
        ...state,
        ...action.status,
        bill: {
          amount: action.payload.data.bill.amount,
          breakdown: {
            servicePrice: action.payload.data.bill.breakdown.service_price,
            total: action.payload.data.bill.breakdown.total,
            vat: action.payload.data.bill.breakdown.vat,
          },
          createdAt: action.payload.data.bill.created_at,
          id: action.payload.data.bill.id,
          status: action.payload.data.bill.status,
          uuid: action.payload.data.bill.uuid,
        },
        createdAt: action.payload.data.created_at,
        displayId: action.payload.data.display_id,
        status: action.payload.data.status,
      }
    }

    default:
      return state
  }
}
