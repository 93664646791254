import { ListingCVRequest } from 'common/types/commonTypes'
import { CallAPIAction, Status } from 'api/types'
import Actions from 'redux/actions'
import { convertListingCVRequestToState, getEmptyListingCVRequest } from 'common/utils/listingRequestHelper'
import { GetListingRequestResponse } from 'api/contractAuthAPI'

type State = {
  data: ListingCVRequest
} & Status

const initialState: State = {
  isLoading: false,
  success: false,
  fail: false,
  data: getEmptyListingCVRequest(),
}

export default (
  state: State = initialState,
  action: CallAPIAction<
    Actions.GET_LISTING_REQUEST_ASYNC,
    Actions.GET_LISTING_REQUEST_SUCCESS,
    Actions.GET_LISTING_REQUEST_FAIL,
    GetListingRequestResponse
  >,
): State => {
  switch (action.type) {
    case Actions.GET_LISTING_REQUEST_SUCCESS: {
      const request = [action.payload.data].map(convertListingCVRequestToState)

      return {
        ...state,
        ...action.status,
        data: request[0],
      }
    }
    case Actions.GET_LISTING_REQUEST_ASYNC:
    case Actions.GET_LISTING_REQUEST_FAIL: {
      return {
        ...state,
        ...action.status,
      }
    }
    default:
      return state
  }
}
