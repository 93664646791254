import { IS_DEV } from 'common/envConstants'
import { setRegularVisaFlowData } from 'redux/actionCreators/regularVisaFlow'
import Actions from 'redux/actions'
import { PhoneData } from 'ui-components/inputs/PhoneInput/PhoneInput'

export type State = {
  acknowledge: boolean
  gender: string
  occupation: number
  nationality: number
  nicCode: number
  name: string
  ageRange: string
  occupationCode: string
  passportNumber: string
  passportExpiryDate: string
  phoneNumber: { value: string; location?: PhoneData }
  passportImage?: File
  offer: {
    id: number
    name: string
    unifiedId: string | null
    contactNumber: string
    email: string
  } | null
  pros: Array<string> | null
  page: number
}

const initialState: State = {
  page: 0,
  acknowledge: false,
  gender: 'female',
  occupation: 5,
  nationality: 2,
  occupationCode: '911102',
  nicCode: IS_DEV ? 368 : 315,
  name: '',
  ageRange: '',
  passportNumber: '',
  passportExpiryDate: '',
  phoneNumber: { value: '', location: undefined },
  passportImage: undefined,
  offer: null,
  pros: null,
}

export default (state: State = initialState, action: ReturnType<typeof setRegularVisaFlowData>) => {
  switch (action.type) {
    case Actions.SET_LABORER_INTERVIEW_DATA:
      return {
        ...state,
        ...action.payload.data,
      }
    default:
      return state
  }
}
