import { CallAPIAction, Status } from 'api/types'
import Actions from 'redux/actions'
import { GetEmployerLaborerDetailsResponse } from 'api/contractAuthAPI'
import { EmployerLaborerInfo } from 'common/types/transformedRespTypes'
import { formatDateForUI } from 'common/utils/common'

type State = {
  data: EmployerLaborerInfo
} & Status

const initialState = {
  data: {
    id: 0,
    name: {
      id: 0,
      label: '',
      labelEn: '',
    },
    iqamaNumber: '',
    borderNumber: '',
    occupation: null,
    nationality: null,
    salary: '',
    mobileNumber: '',
    sponsor: {
      name: '',
      nameEn: '',
    },
    laborerProfile: '',
    status: '',
    gender: '',
    birthDate: '',
    religion: '',
    martialStatus: '',
    entryDate: '',
    visaId: '',
    contract: null,
    expiryDate: '',
  },
  isLoading: false,
  success: false,
  fail: false,
}

export default (
  state: State = initialState,
  action: CallAPIAction<
    Actions.GET_EMPLOYER_LABORER_DETAILS_ASYNC,
    Actions.GET_EMPLOYER_LABORER_DETAILS_SUCCESS,
    Actions.GET_EMPLOYER_LABORER_DETAILS_FAIL,
    GetEmployerLaborerDetailsResponse
  >,
): State => {
  switch (action.type) {
    case Actions.GET_EMPLOYER_LABORER_DETAILS_ASYNC:
    case Actions.GET_EMPLOYER_LABORER_DETAILS_FAIL: {
      return {
        ...state,
        ...action.status,
      }
    }
    case Actions.GET_EMPLOYER_LABORER_DETAILS_SUCCESS: {
      const { data } = action.payload
      return {
        ...state,
        ...action.status,
        data: {
          id: data.id,
          name: {
            id: 0,
            label: data.name,
            labelEn: data.name_en,
          },
          iqamaNumber: data.iqama_number,
          expiryDate: formatDateForUI(data.iqama_expiry_date),
          borderNumber: data.border_number,
          occupation: data.occupation && {
            id: data.occupation.id,
            label: data.occupation.label,
            labelEn: data.occupation.label_en,
          },
          nationality: data.nationality && {
            id: data.nationality.id,
            label: data.nationality.label,
            labelEn: data.nationality.label_en,
          },
          salary: data.salary,
          mobileNumber: data.mobile_number,
          sponsor: {
            name: data.sponsor.name,
            nameEn: data.sponsor.name_en,
          },
          laborerProfile: data.laborer_profile,
          status: data.status,
          gender: data.gender,
          birthDate: data.birth_date,
          religion: data.religion,
          martialStatus: data.martial_status,
          entryDate: data.entry_date,
          visaId: data.visa_id,
          contract: data.contract && {
            id: data.contract.id,
            relatableId: data.contract.relatable_id,
            relatableSource: data.contract.relatable_source,
            startDate: data.contract.start_date,
            status: data.contract.status,
          },
        },
      }
    }

    default:
      return state
  }
}
