import { GetListingCVsListResponse } from 'api/contractAuthAPI'
import { CallAPIAction, Status } from 'api/types'
import { ListingCVRequest } from 'common/types/commonTypes'
import { convertListingCVRequestToState } from 'common/utils/listingRequestHelper'
import Actions from 'redux/actions'

type State = {
  data: Array<ListingCVRequest>
  lastPage: number
} & Status

const initialState: State = {
  isLoading: false,
  success: false,
  fail: false,
  data: [],
  lastPage: 0,
}

export default (
  state: State = initialState,
  action: CallAPIAction<
    Actions.GET_LISTING_CVS_LIST_ASYNC,
    Actions.GET_LISTING_CVS_LIST_SUCCESS,
    Actions.GET_LISTING_CVS_LIST_FAIL,
    GetListingCVsListResponse
  >,
) => {
  switch (action.type) {
    case Actions.GET_LISTING_CVS_LIST_SUCCESS:
      return {
        ...state,
        ...action.status,
        data: action.payload.data.map(convertListingCVRequestToState),
        lastPage: action.payload.meta.last_page,
      }
    case Actions.GET_LISTING_CVS_LIST_ASYNC:
    case Actions.GET_LISTING_CVS_LIST_FAIL:
      return {
        ...state,
        ...action.status,
      }
    default:
      return state
  }
}
