import { CallAPIAction, Status } from 'api/types'
import Actions from 'redux/actions'

type State = {
  errorMessage: string
} & Status

type FailResponse = {
  error?: {
    message?: string
  }
}

const initialState: State = {
  errorMessage: '',
  isLoading: false,
  success: false,
  fail: false,
}

export default (
  state: State = initialState,
  action: CallAPIAction<
    Actions.CANCEL_LISTING_LABORER_CV_ASYNC,
    Actions.CANCEL_LISTING_LABORER_CV_SUCCESS,
    Actions.CANCEL_LISTING_LABORER_CV_FAIL,
    {},
    FailResponse
  >,
) => {
  switch (action.type) {
    case Actions.CANCEL_LISTING_LABORER_CV_SUCCESS:
    case Actions.CANCEL_LISTING_LABORER_CV_ASYNC:
      return {
        ...state,
        ...action.status,
      }

    case Actions.CANCEL_LISTING_LABORER_CV_FAIL:
      return {
        ...state,
        ...action.status,
        errorMessage: action.payload?.error?.message || '',
      }
    default:
      return state
  }
}
