import Actions from 'redux/actions'
import type { CallAPIAction, Status } from 'api/types'
import { GetLaborerProsResponse } from 'api/interviewAPI'
import { InterviewLaborerStatus } from 'common/types/commonTypes'

type State = {
  laborerStatus: InterviewLaborerStatus
  pros: Array<string> | null
} & Status

const initialState = {
  laborerStatus: InterviewLaborerStatus.LABORER_NOT_AVAILABLE,
  pros: null,
  isLoading: false,
  success: false,
  fail: false,
}

export default (
  state: State = initialState,
  action: CallAPIAction<
    Actions.GET_LABORER_INTERVIEW_PROS_ASYNC,
    Actions.GET_LABORER_INTERVIEW_PROS_SUCCESS,
    Actions.GET_LABORER_INTERVIEW_PROS_FAIL,
    GetLaborerProsResponse
  >,
): State => {
  switch (action.type) {
    case Actions.GET_LABORER_INTERVIEW_PROS_ASYNC:
    case Actions.GET_LABORER_INTERVIEW_PROS_FAIL: {
      return {
        ...state,
        ...action.status,
      }
    }

    case Actions.GET_LABORER_INTERVIEW_PROS_SUCCESS: {
      return {
        ...state,
        ...action.status,
        laborerStatus: action.payload.status,
        pros: action.payload.pros,
      }
    }
    default:
      return state
  }
}
