import type { AxiosRequestConfig } from 'axios'

import { callAPI, laborerWpsAPIWithCredentials } from 'api/axios'
import { GeneralFailResponse, TransactionFromBE } from 'common/types/commonTypes'

export type GetTransactionsListParams = {
  iqamaNumber: string
}

export type GetTransactionsListResponse = {
  data: Array<TransactionFromBE>
}

const getTransactionsList = ({ iqamaNumber }: GetTransactionsListParams, config?: AxiosRequestConfig) =>
  laborerWpsAPIWithCredentials.get<GetTransactionsListResponse>('api/v1.0/laborer/transactions/offline/index', {
    params: {
      iqama_number: iqamaNumber,
      per_page: 99,
    },
    ...config,
  })

export type GetTransactionDetailsParams = {
  id: string
}

export type GetTransactionDetailsResponse = {
  data: TransactionFromBE
}

const getTransactionDetails = ({ id }: GetTransactionDetailsParams, config?: AxiosRequestConfig) =>
  laborerWpsAPIWithCredentials.get<GetTransactionDetailsResponse>(
    `api/v1.0/laborer/transactions/offline/${id}`,
    config,
  )

export type AcceptSalaryPaymentParams = {
  id: number
}

const acceptSalaryPayment = ({ id }: AcceptSalaryPaymentParams, config?: AxiosRequestConfig) =>
  laborerWpsAPIWithCredentials.post<{}>(`api/v1.0/laborer/transactions/offline/${id}/approve`, config)

export type RejectSalaryPaymentParams = {
  id: number
}

const rejectSalaryPayment = ({ id }: RejectSalaryPaymentParams, config?: AxiosRequestConfig) =>
  laborerWpsAPIWithCredentials.post<{}>(`api/v1.0/laborer/transactions/offline/${id}/reject`, config)

export default {
  getTransactionsList: callAPI<typeof getTransactionsList, GetTransactionsListResponse, GeneralFailResponse>(
    getTransactionsList,
  ),
  getTransactionDetails: callAPI<typeof getTransactionDetails, GetTransactionDetailsResponse, GeneralFailResponse>(
    getTransactionDetails,
  ),
  acceptSalaryPayment: callAPI<typeof acceptSalaryPayment, {}, GeneralFailResponse>(acceptSalaryPayment),
  rejectSalaryPayment: callAPI<typeof rejectSalaryPayment, {}, GeneralFailResponse>(rejectSalaryPayment),
}
