import { takeLatest } from 'redux-saga/effects'

import Actions from 'redux/actions'
import {
  getLaborersList as getLaborersListAction,
  getCurrentLaborer as getCurrentLaborerAction,
  getLaborerTransactions as getLaborerTransactionsAction,
  getCurrentTransaction as getCurrentTransactionAction,
  getCommitmentsStatistic as getCommitmentsStatisticAction,
  submitOfflinePayment as submitOfflinePaymentAction,
} from 'redux/actionCreators/wpsAPI'
import wpsAPI from 'api/wpsAPI'

import { getSimpleRequestHandler } from '../utils'

const getLaborersList = getSimpleRequestHandler<
  typeof wpsAPI.getLaborersList,
  ReturnType<typeof getLaborersListAction>
>({
  actionLoading: Actions.GET_WPS_LABORERS_LIST_ASYNC,
  actionSuccess: Actions.GET_WPS_LABORERS_LIST_SUCCESS,
  actionFail: Actions.GET_WPS_LABORERS_LIST_FAIL,
  callApiFn: wpsAPI.getLaborersList,
})

const getCurrentLabor = getSimpleRequestHandler<
  typeof wpsAPI.getCurrentLabor,
  ReturnType<typeof getCurrentLaborerAction>
>({
  actionLoading: Actions.GET_WPS_CURRENT_LABORER_ASYNC,
  actionSuccess: Actions.GET_WPS_CURRENT_LABORER_SUCCESS,
  actionFail: Actions.GET_WPS_CURRENT_LABORER_FAIL,
  callApiFn: wpsAPI.getCurrentLabor,
})

const getTransactions = getSimpleRequestHandler<
  typeof wpsAPI.getTransactions,
  ReturnType<typeof getLaborerTransactionsAction>
>({
  actionLoading: Actions.GET_WPS_LABORER_TRANSACTIONS_ASYNC,
  actionSuccess: Actions.GET_WPS_LABORER_TRANSACTIONS_SUCCESS,
  actionFail: Actions.GET_WPS_LABORER_TRANSACTIONS_FAIL,
  callApiFn: wpsAPI.getTransactions,
})

const getCurrentTransaction = getSimpleRequestHandler<
  typeof wpsAPI.getCurrentTransaction,
  ReturnType<typeof getCurrentTransactionAction>
>({
  actionLoading: Actions.GET_WPS_CURRENT_TRANSACTION_ASYNC,
  actionSuccess: Actions.GET_WPS_CURRENT_TRANSACTION_SUCCESS,
  actionFail: Actions.GET_WPS_CURRENT_TRANSACTION_FAIL,
  callApiFn: wpsAPI.getCurrentTransaction,
})

const getCommitmentsStatistic = getSimpleRequestHandler<
  typeof wpsAPI.getCommitmentsStatistic,
  ReturnType<typeof getCommitmentsStatisticAction>
>({
  actionLoading: Actions.GET_WPS_COMMITMENTS_STATISTIC_ASYNC,
  actionSuccess: Actions.GET_WPS_COMMITMENTS_STATISTIC_SUCCESS,
  actionFail: Actions.GET_WPS_COMMITMENTS_STATISTIC_FAIL,
  callApiFn: wpsAPI.getCommitmentsStatistic,
})

const submitOfflinePayment = getSimpleRequestHandler<
  typeof wpsAPI.submitOfflinePayment,
  ReturnType<typeof submitOfflinePaymentAction>
>({
  actionLoading: Actions.SUBMIT_OFFLINE_PAYMENT_ASYNC,
  actionSuccess: Actions.SUBMIT_OFFLINE_PAYMENT_SUCCESS,
  actionFail: Actions.SUBMIT_OFFLINE_PAYMENT_FAIL,
  callApiFn: wpsAPI.submitOfflinePayment,
})

export default [
  takeLatest(Actions.GET_WPS_LABORERS_LIST, getLaborersList),
  takeLatest(Actions.GET_WPS_CURRENT_LABORER, getCurrentLabor),
  takeLatest(Actions.GET_WPS_LABORER_TRANSACTIONS, getTransactions),
  takeLatest(Actions.GET_WPS_CURRENT_TRANSACTION, getCurrentTransaction),
  takeLatest(Actions.GET_WPS_COMMITMENTS_STATISTIC, getCommitmentsStatistic),
  takeLatest(Actions.SUBMIT_OFFLINE_PAYMENT, submitOfflinePayment),
]
