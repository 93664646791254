import moment from 'moment'

import { DateType } from 'common/types/commonTypes'

import {
  DATE_REG_EXP,
  MADA_BINS,
  MAX_AUTH_CONTRACT_DURATION,
  MAX_SALARY,
  MIN_AUTH_CONTRACT_DURATION,
  MIN_SALARY,
  MONTH_YEAR_DATE_REG_EXP,
} from '../../constants'

export type Rules = {
  required?: boolean
  minLength?: number
  maxLength?: number
  exactLength?: number
  digitsOnly?: boolean
  saudiIqama?: boolean
  saudiIdOrIqama?: boolean
  saudiId?: boolean
  saudiTelephoneNumber?: boolean
  mobileNumber?: boolean
  phMobileNumber?: boolean
  email?: boolean
  alphaNumeric?: boolean
  fullName?: boolean
  saMobileNumber?: boolean
  saNumber?: boolean
  match?: string
  date?: boolean
  monthYearDate?: boolean
  masterCard?: boolean
  visaCard?: boolean
  madaCard?: boolean
  cvv?: boolean
  startFromLetter?: boolean
  LANPhoneNumber?: boolean
  passportExpiryDate?: boolean
  IBANValidation?: boolean
  digitsOrLetters?: boolean
  nameInArabicOnly?: boolean
  aplhaNumWithSpace?: boolean
  creditCardExpiry?: boolean
  uniqueMobile?: string
  salary?: boolean
  banCurrentIqama?: number
  contractDuration?: boolean
  supportPhoneNumber?: boolean
  serviceCost?: boolean
  maximumCost?: number
  minimumCost?: number
  day?: boolean
  futureYear?: boolean
  year?: boolean
  expiryDateMonthAmount?: number
  usedMonths?: number
  originalSalary?: string
  lettersOnly?: boolean
  minimumWage?: boolean
  maximumWage?: boolean
  noAgent?: boolean
}

export const isMadaCard = (number: string) => {
  const re = new RegExp(`^(${MADA_BINS.join('|')})`)
  return re.test(number)
}

export const lowercaseLetterValidation = (input: string) => {
  const re = /[a-z]/
  return re.test(input)
}

export const uppercaseLetterValidation = (input: string) => {
  const re = /[A-Z]/
  return re.test(input)
}

export const symbolValidation = (input: string) => {
  const re = /[$@!%*?&#^_.-]/
  return re.test(input)
}

export const digitValidation = (input: string) => {
  const re = /[0-9]/
  return re.test(input)
}

const saudiIdValidation = (idNumber: string) => {
  let sum = 0
  const digits = idNumber.split('')

  for (let i = 0; i < 10; i += 1) {
    if (i % 2 === 0) {
      const tempSum = parseInt(digits[i], 10) * 2
      sum += (tempSum % 10) + Math.floor(tempSum / 10)
    } else {
      sum += parseInt(digits[i], 10)
    }
  }

  return sum % 10 === 0
}

const checkCard = (ccNum: string) => {
  const arr = [0, 2, 4, 6, 8, 1, 3, 5, 7, 9]
  let len = ccNum.length
  let bit = 1
  let sum = 0
  let val
  while (len) {
    val = parseInt(ccNum.charAt(--len), 10)
    // eslint-disable-next-line no-bitwise
    bit ^= 1
    sum += bit ? arr[val] : val
  }
  return sum && sum % 10 === 0
}

const saudiTelephoneNumberValidation = (mobileNumber: string) => {
  const regex = /^(011|012|013|014|016|017)\d{7}$/
  return regex.test(mobileNumber)
}

const mobileNumberValidation = (phoneNumber: string) => {
  const regex = /^[1-9]{1}[0-9]{7,14}$/
  return regex.test(phoneNumber)
}

const saudiMobileNumberValidation = (mobileNumber: string) => {
  const regex = /^[0]?(5)\d{8}$/
  return regex.test(mobileNumber)
}
const saudiNumberValidation = (mobileNumber: string) => {
  const regex = /^[0]?(5|1)\d{8}$/
  return regex.test(mobileNumber)
}

const philippineMobileNumberValidation = (mobileNumber: string) => {
  const regex = /^(09|9)\d{8,9}$/
  return regex.test(mobileNumber)
}

const digitsOnlyValidation = (input: string) => {
  const re = /^[0-9]+$/
  return re.test(input)
}

const emailValidation = (email: string) => {
  const re = /^[a-zA-Z0-9._-]+@([A-Za-z]+\.[A-Za-z]+|[A-Za-z]+\.[A-Za-z]+\.[A-Za-z]+)$/
  return re.test(email.toLowerCase())
}

const fullNameValidation = (name: string) => {
  const re = /^((?:[A-Za-z'-.]* ?)*)$/
  return re.test(name)
}

const alphaNumericValidation = (input: string) => {
  const re = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[$@!%*?&#^_.-])[A-Za-z\d$@!%*?&#^_.-]{8,}/
  return re.test(input)
}

const masterCardValidation = (number: string) => {
  const re = /^(5[1-5][0-9]{2}|222[1-9]|22[3-9][0-9]|2[3-6][0-9]{2}|27[01][0-9]|2720)\d{12}$/
  return re.test(number) && checkCard(number) && !isMadaCard(number)
}

const visaCardValidation = (number: string) => {
  const re = /^4\d{12}(\d{3}|\d{6})?$/
  return re.test(number) && checkCard(number) && !isMadaCard(number)
}

const madaCardValidation = (number: string) => isMadaCard(number) && checkCard(number)

const cvvValidation = (cvv: string) => {
  const re = /^[0-9]{3}$/
  return re.test(cvv)
}

const startFromLetter = (string: string) => {
  const re = /^[A-Za-z][A-Za-z0-9 -]*$/
  return re.test(string)
}

const LANPhoneNumber = (string: string) => {
  const re = /^01[0-9]{8}$/
  return re.test(string)
}

const supportPhoneNumber = (string: string) => {
  const re = /^05[0-9]{8}|9665[0-9]{8}$/
  return re.test(string)
}

const IBANValidation = (inputIban: string) => {
  let pattern
  let iban = inputIban.toUpperCase()

  if (!digitsOrLetters(iban)) return false

  pattern = /^\D\D\d\d.+/ // Invalid IBAN structure
  if (!pattern.test(iban)) return false

  pattern = /^\D\D00.+|^\D\D01.+|^\D\D99.+/ // Check digits cannot be 00, 01 or 99
  if (pattern.test(iban)) return false

  pattern = /^SA..(05|65|60|10|15|20|30|40|45|50|80|55|95|75|81|90)[A-Z0-9]{18}$/ // Start from SA, then supported banks
  if (!pattern.test(iban)) return false

  const saudiIbanLength = 24
  // Move country and check digits to the end
  iban = iban.substring(4, saudiIbanLength) + iban.substring(0, 4)

  // Replace letters with digits

  const SHIFT = 55 // Replace letter with corresponding digit
  const replacedIban = iban
    .split('')
    .map((char: string) => {
      if (digitsOnlyValidation(char)) {
        return char
      }
      return (char.charCodeAt(0) - SHIFT).toString()
    })
    .join('')

  // Calculate modulo 97 remainder
  const partsCount = Math.ceil(replacedIban.length / 7)
  let remainder = 0
  for (let i = 0; i <= partsCount; i++) {
    const from = i * 7
    const to = from + 7
    remainder = parseFloat(remainder + replacedIban.substring(from, to)) % 97
  }
  // Remainder must be 1
  return remainder === 1
}

const digitsOrLetters = (value: string) => {
  const re = /^[A-Za-z0-9]*$/
  return re.test(value)
}

const passportExpiryDate = (date: string) => {
  const currentDate = new Date()
  const eightMonthFurther = currentDate.setMonth(currentDate.getMonth() + 8)
  const subtract = +new Date(date) - eightMonthFurther
  return Math.sign(subtract) === 1
}

const nameInArabicOnly = (name: string) => {
  const re = /^([ء-ي]\s{0,1})+[ء-ي]$/
  return re.test(name)
}

const aplhaNumWithSpaceValidation = (value: string) => {
  const regex = /^[a-zA-Zء-ي0-9٠-٩\s\\._-]+$/
  return regex.test(value)
}

const dateValidator = (date: string) => DATE_REG_EXP.test(date) && moment(date).isValid()

const monthYearDateValidator = (date: string) => MONTH_YEAR_DATE_REG_EXP.test(date) && moment(date).isValid()

const creditCardExpiryDateValidator = (maskedDate: string): [boolean, Array<string>] => {
  const [month, yearShort] = maskedDate.split('/').map((value) => parseInt(value, 10))
  const errors = []
  if (month < 1 || month > 12 || typeof yearShort === 'undefined') {
    errors.push('invalidCardExpiryDate')
  }
  const today = new Date()
  const currentMillennium = today.getFullYear() - (today.getFullYear() % 100)
  const expDate = new Date(yearShort + currentMillennium, month)
  if (expDate <= today) {
    errors.push('cardExpiryDateFuture')
  }
  return [!errors.length, errors]
}

const checkDateValidity = (value: DateType, rules: Rules = {}) => {
  let isDayValid = true
  let isMonthValid = true
  let isYearValid = true
  const errors: Array<string> = []

  if (rules.required) {
    isDayValid = !!value.day
    isMonthValid = !!value.month
    isYearValid = !!value.year
    if (!isDayValid || !isMonthValid || !isYearValid) {
      errors.push('required')
      return { errorDetails: { isDayValid, isMonthValid, isYearValid }, errors }
    }
  }
  return { errorDetails: { isDayValid, isMonthValid, isYearValid }, errors }
}

const dayValidation = (day: string) => {
  const re = /^(0[1-9]|3[0]|[12][0-9]|[1-9])$/
  return re.test(day)
}

const futureYearValidation = (year: string) => {
  const re = /^129[5-9]$|^13[0-9][0-9]$|^14[0-4][0-9]$|^145[0-4]$/
  return re.test(year)
}

const yearValidation = (year: string) => {
  const re = /^129[5-9]$|^13[0-9][0-9]$|^14[0-3][0-9]$|^144[0-4]$/
  return re.test(year)
}

const lettersOnly = (value: string) => {
  const re = /^[A-Za-zء-ي\s\\._-]*$/
  return re.test(value)
}

const getContractDurationTranslations = (usedMonths: number) => {
  if (usedMonths === 1) {
    return 'contractDurationOne'
  } else if (usedMonths === 2) {
    return 'contractDurationTwo'
  } else {
    return 'contractDurationOne'
  }
}

const checkValidity = (value: string, rules: Rules) => {
  let isValid = true
  const errors: Array<string | [string, number]> = []

  if (rules.required) {
    isValid = typeof value === 'string' ? !!value.trim() : value && isValid
    if (!isValid) {
      errors.push('required')
      return { isValid, errors }
    }
  } else if (!value || (typeof value === 'string' && !value.trim())) {
    return { isValid, errors }
  }

  if (rules.minLength && !(value.length >= rules.minLength)) {
    errors.push(['minLength', rules.minLength])
  }

  if (rules.maxLength && !(value.length <= rules.maxLength)) {
    errors.push(['maxLength', rules.maxLength])
  }

  if (rules.exactLength && !(value.length === rules.exactLength)) {
    errors.push(['exactLength', rules.exactLength])
  }

  if (rules.contractDuration) {
    isValid =
      !Number.isNaN(Number(value)) &&
      (rules.usedMonths ? Number(value) > Number(rules.usedMonths) : Number(value) >= MIN_AUTH_CONTRACT_DURATION) &&
      Number(value) <= MAX_AUTH_CONTRACT_DURATION
    if (!isValid) {
      Number(rules.usedMonths) && Number(rules.usedMonths) >= 3
        ? errors.push(['contractDuration', Number(rules.usedMonths)])
        : errors.push(getContractDurationTranslations(Number(rules.usedMonths)))
    }
  }

  if (rules.banCurrentIqama && parseInt(value) === rules.banCurrentIqama) {
    errors.push(['banCurrentIqama', rules.banCurrentIqama])
  }

  if (rules.digitsOnly && !digitsOnlyValidation(value)) {
    errors.push('digitsOnly')
  }

  if (rules.saudiId && !(saudiIdValidation(value) && value[0] === '1')) {
    errors.push('saudiId')
  }

  if (rules.saudiIqama && !(saudiIdValidation(value) && value[0] === '2')) {
    errors.push('saudiIqama')
  }

  if (rules.saudiIdOrIqama && !saudiIdValidation(value)) {
    errors.push('saudiIdOrIqama')
  }

  if (rules.noAgent && !saudiIdValidation(value)) {
    errors.push('noAgent')
  }

  if (rules.saudiTelephoneNumber && !saudiTelephoneNumberValidation(value)) {
    errors.push('saudiTelephoneNumber')
  }

  if (rules.mobileNumber && !mobileNumberValidation(value)) {
    errors.push('mobileNumber')
  }

  if (rules.saMobileNumber && !saudiMobileNumberValidation(value)) {
    errors.push('saudiMobileNumber')
  }

  if (rules.phMobileNumber && !philippineMobileNumberValidation(value)) {
    errors.push('philippineMobileNumber')
  }

  if (rules.email && !emailValidation(value)) {
    errors.push('email')
  }

  if (rules.alphaNumeric && !alphaNumericValidation(value)) {
    errors.push('alphaNumeric')
  }

  if (rules.fullName && !fullNameValidation(value)) {
    errors.push('fullName')
  }

  if (rules.saNumber && !saudiNumberValidation(value)) {
    errors.push('saudiNumber')
  }

  if (rules.match && value !== rules.match) {
    errors.push('match')
  }

  if (rules.uniqueMobile && value === rules.uniqueMobile) {
    errors.push('uniqueMobile')
  }

  if (rules.date && !dateValidator(value)) {
    errors.push('date')
  }

  if (rules.monthYearDate && !monthYearDateValidator(value)) {
    errors.push('monthYearDate')
  }

  if (rules.masterCard && !masterCardValidation(value)) {
    errors.push('card')
  }

  if (rules.visaCard && !visaCardValidation(value)) {
    errors.push('card')
  }

  if (rules.madaCard && !madaCardValidation(value)) {
    errors.push('card')
  }

  if (rules.creditCardExpiry) {
    const [creditCardExpiryIsValid, creditCardExpiryErrors] = creditCardExpiryDateValidator(value)
    if (!creditCardExpiryIsValid) errors.push(...creditCardExpiryErrors)
  }

  if (rules.cvv) {
    isValid = cvvValidation(value) && isValid
    if (!isValid) errors.push('cvv')
  }

  if (rules.startFromLetter) {
    isValid = startFromLetter(value) && isValid
    if (!isValid) errors.push('startFromLetter')
  }

  if (rules.passportExpiryDate) {
    isValid = passportExpiryDate(value) && isValid
    if (!isValid) errors.push(['passportExpiryDate', Number(rules.expiryDateMonthAmount) || 8])
  }

  if (rules.IBANValidation) {
    isValid = IBANValidation(value) && isValid
    if (!isValid) errors.push('IBANValidation')
  }

  if (rules.digitsOrLetters) {
    isValid = digitsOrLetters(value) && isValid
    if (!isValid) errors.push('digitsOrLetters')
  }

  if (rules.nameInArabicOnly) {
    isValid = nameInArabicOnly(value) && isValid
    if (!isValid) errors.push('nameInArabicOnly')
  }

  if (rules.aplhaNumWithSpace) {
    isValid = aplhaNumWithSpaceValidation(value) && isValid
    if (!isValid) errors.push('aplhaNumWithSpace')
  }

  if (rules.salary) {
    const minSalary = Number(rules.originalSalary) || MIN_SALARY
    isValid = !Number.isNaN(Number(value)) && Number(value) >= minSalary && Number(value) <= MAX_SALARY
    if (!isValid) errors.push('salary')
  }

  if (rules.minimumWage) {
    const minSalary = Number(rules.originalSalary) || MIN_SALARY
    isValid = !Number.isNaN(Number(value)) && Number(value) >= minSalary
    if (!isValid) errors.push('minimumWage')
  }

  if (rules.maximumWage) {
    isValid = !Number.isNaN(Number(value)) && Number(value) <= MAX_SALARY
    if (!isValid) errors.push('maximumWage')
  }

  if (rules.LANPhoneNumber) {
    isValid = LANPhoneNumber(value) && isValid
    if (!isValid) errors.push('LANPhoneNumber')
  }

  if (rules.supportPhoneNumber) {
    isValid = supportPhoneNumber(value) && isValid
    if (!isValid) errors.push('saudiMobileNumber')
  }

  if (rules.maximumCost && +value > rules.maximumCost) {
    errors.push(['maxServiceCost', Number(rules.maximumCost)])
  }

  if (rules.minimumCost && +value < rules.minimumCost) {
    errors.push(['minServiceCost', Number(rules.minimumCost)])
  }

  if (rules.serviceCost) {
    const checkZeros = () => value.split('').every((symbol) => symbol === '0')
    isValid = !Number.isNaN(Number(value)) && +value >= 0 && !(value.length > 1 && checkZeros())
    if (!isValid) errors.push('serviceCostError')
  }

  if (rules.day) {
    isValid = dayValidation(value) && isValid
    if (!isValid) errors.push('validDate')
  }

  if (rules.futureYear) {
    isValid = futureYearValidation(value) && isValid
    if (!isValid) errors.push('validDate')
  }

  if (rules.year) {
    isValid = yearValidation(value) && isValid
    if (!isValid) errors.push('validDate')
  }

  if (rules.lettersOnly) {
    isValid = lettersOnly(value) && isValid
    if (!isValid) errors.push('lettersOnly')
  }

  return { isValid: !errors.length, errors }
}

export { checkValidity, checkDateValidity }
