import { takeLatest, put, call, all, takeLeading, takeEvery } from 'redux-saga/effects'

import Actions from 'redux/actions'
import {
  getNationalities as getNationalitiesAction,
  getOccupations as getOccupationsAction,
  getLaborOffices as getLaborOfficesAction,
  getArrivalCities as getArrivalCitiesAction,
  getDefaultArrivalCity as getDefaultArrivalCityAction,
  getWorkersList as getWorkersListAction,
  getLocationDetails as getLocationDetailsAction,
  saveUserData as saveUserDataAction,
  changePassword as changePasswordAction,
  getVisaIssuePlaces as getVisaIssuePlacesAction,
  getEligibleWorkers as getEligibleWorkersAction,
  getReplaceableVisa as getReplaceableVisaAction,
  getSkills as getSkillsAction,
  getRejectionReasonsList as getRejectionReasonsListAction,
  cancelVisa as cancelVisaAction,
  makeRefundSADAD as makeRefundSADADAction,
  makeRefundPayFort as makeRefundPayFortAction,
  checkOutdatedIBAN as checkOutdatedIBANAction,
  getDownloadHistory as getDownloadHistoryAction,
  getDownloadHistoryDetails as getDownloadHistoryDetailsAction,
  getLabors as getLaborsAction,
  updateLabors as updateLaborsAction,
  getRefundableBills as getRefundableBillsAction,
  submitWataniSurvey as submitWataniSurveyAction,
  surveySubmit as surveySubmitAction,
  getOccupationDescriptions as getOccupationDescriptionsAction,
  updatePersonalDetails as updatePersonalDetailsAction,
  removeFromPilotList as removeFromPilotListAction,
  getRunaways as getRunawaysAction,
  reportRunawayLaborer as reportRunawayLaborerAction,
  cancelReportRunawayLaborer as cancelReportRunawayLaborerAction,
  getEligibleLaborers as getEligibleLaborersAction,
  checkFeesExemption as checkFeesExemptionAction,
  uploadDisabilityProof as uploadDisabilityProofAction,
  submitFeesExemption as submitFeesExemptionAction,
  updateNationalAddress as updateNationalAddressAction,
  setNewPrimaryAddress as setNewPrimaryAddressAction,
  getVisaInvoiceFile as getInvoiceFileAction,
  sendAbsherForUpdatingMobile as sendAbsherForUpdatingMobileAction,
  verifyAbsherForUpdatingMobile as verifyAbsherForUpdatingMobileAction,
  sendOtpForUpdatingMobile as sendOtpForUpdatingMobileAction,
  verifyOtpForUpdatingMobile as verifyOtpForUpdatingMobileAction,
  createAgent as createAgentAction,
  verifyAgent as verifyAgentAction,
  removeAgent as removeAgentAction,
  updateUserContacts as updateUserContactsAction,
  authLogout as authLogoutAction,
} from 'redux/actionCreators/evisaAPI/evisaAPI'
import evisaAPI, {
  DownloadHistoryResponse,
  GetDefaultArrivalCityResponse,
  GetUserProfileResponse,
} from 'api/evisaAPI/evisaAPI'
import { setMarketFilter } from 'redux/actionCreators/marketFilter'
import { DEFAULT_ARRIVAL_CITY_ID } from 'common/constants'
import { Await, GeneralFailResponse, ValidationErrors } from 'common/types/commonTypes'
import authManager from 'common/utils/auth/authManager'
import { formatValidationMessages } from 'common/utils/common'
import { CallApiFnResponse } from 'api/axios'
import convertUserProfileResponse, {
  getFilteredIbanValidationMessage,
} from 'common/utils/convertResponse/convertUserProfileResponse'
import { setRecruitmentFilter } from 'redux/actionCreators/recruitmentFilter'
import { setVipRecruitmentFilter } from 'redux/actionCreators/premiumUserAPI/recruitmentFilter'

import { getSimpleRequestHandler, putFail, putLoading, putSuccess } from '../../utils'

import { getAuthData } from './authSaga'

const getNationalities = getSimpleRequestHandler<
  typeof evisaAPI.getNationalities,
  ReturnType<typeof getNationalitiesAction>
>({
  actionLoading: Actions.GET_NATIONALITIES_ASYNC,
  actionSuccess: Actions.GET_NATIONALITIES_SUCCESS,
  actionFail: Actions.GET_NATIONALITIES_FAIL,
  callApiFn: evisaAPI.getNationalities,
})

const getVisaInvoiceFile = getSimpleRequestHandler<
  typeof evisaAPI.getVisaInvoiceFile,
  ReturnType<typeof getInvoiceFileAction>
>({
  actionLoading: Actions.GET_VISA_INVOICE_FILE_ASYNC,
  actionSuccess: Actions.GET_VISA_INVOICE_FILE_SUCCESS,
  actionFail: Actions.GET_VISA_INVOICE_FILE_FAIL,
  callApiFn: evisaAPI.getVisaInvoiceFile,
})

const getOccupations = getSimpleRequestHandler<
  typeof evisaAPI.getOccupations,
  ReturnType<typeof getOccupationsAction>
>({
  actionLoading: Actions.GET_OCCUPATIONS_ASYNC,
  actionSuccess: Actions.GET_OCCUPATIONS_SUCCESS,
  actionFail: Actions.GET_OCCUPATIONS_FAIL,
  callApiFn: evisaAPI.getOccupations,
})

const sendAbsherForUpdatingMobile = getSimpleRequestHandler<
  typeof evisaAPI.sendAbsherForUpdatingMobile,
  ReturnType<typeof sendAbsherForUpdatingMobileAction>
>({
  actionLoading: Actions.SEND_ABSHER_FOR_UPDATE_MOBILE_ASYNC,
  actionSuccess: Actions.SEND_ABSHER_FOR_UPDATE_MOBILE_SUCCESS,
  actionFail: Actions.SEND_ABSHER_FOR_UPDATE_MOBILE_FAIL,
  callApiFn: evisaAPI.sendAbsherForUpdatingMobile,
})

const verifyAbsherForUpdatingMobile = getSimpleRequestHandler<
  typeof evisaAPI.verifyAbsherForUpdatingMobile,
  ReturnType<typeof verifyAbsherForUpdatingMobileAction>
>({
  actionLoading: Actions.VERIFY_ABSHER_FOR_UPDATE_MOBILE_ASYNC,
  actionSuccess: Actions.VERIFY_ABSHER_FOR_UPDATE_MOBILE_SUCCESS,
  actionFail: Actions.VERIFY_ABSHER_FOR_UPDATE_MOBILE_FAIL,
  callApiFn: evisaAPI.verifyAbsherForUpdatingMobile,
})

const sendOtpForUpdatingMobile = getSimpleRequestHandler<
  typeof evisaAPI.sendOtpForUpdatingMobile,
  ReturnType<typeof sendOtpForUpdatingMobileAction>
>({
  actionLoading: Actions.SEND_OTP_FOR_UPDATE_MOBILE_ASYNC,
  actionSuccess: Actions.SEND_OTP_FOR_UPDATE_MOBILE_SUCCESS,
  actionFail: Actions.SEND_OTP_FOR_UPDATE_MOBILE_FAIL,
  callApiFn: evisaAPI.sendOtpForUpdatingMobile,
})

const verifyOtpForUpdatingMobile = getSimpleRequestHandler<
  typeof evisaAPI.verifyOtpForUpdatingMobile,
  ReturnType<typeof verifyOtpForUpdatingMobileAction>
>({
  actionLoading: Actions.VERIFY_OTP_FOR_UPDATE_MOBILE_ASYNC,
  actionSuccess: Actions.VERIFY_OTP_FOR_UPDATE_MOBILE_SUCCESS,
  actionFail: Actions.VERIFY_OTP_FOR_UPDATE_MOBILE_FAIL,
  callApiFn: evisaAPI.verifyOtpForUpdatingMobile,
})

const getLaborOffices = getSimpleRequestHandler<
  typeof evisaAPI.getLaborOffices,
  ReturnType<typeof getLaborOfficesAction>
>({
  actionLoading: Actions.GET_LABOR_OFFICES_ASYNC,
  actionSuccess: Actions.GET_LABOR_OFFICES_SUCCESS,
  actionFail: Actions.GET_LABOR_OFFICES_FAIL,
  callApiFn: evisaAPI.getLaborOffices,
})

const getArrivalCities = getSimpleRequestHandler<
  typeof evisaAPI.getArrivalCities,
  ReturnType<typeof getArrivalCitiesAction>
>({
  actionLoading: Actions.GET_ARRIVAL_CITIES_ASYNC,
  actionSuccess: Actions.GET_ARRIVAL_CITIES_SUCCESS,
  actionFail: Actions.GET_ARRIVAL_CITIES_FAIL,
  callApiFn: evisaAPI.getArrivalCities,
})

const reportRunawayLaborer = getSimpleRequestHandler<
  typeof evisaAPI.reportRunawayLaborer,
  ReturnType<typeof reportRunawayLaborerAction>
>({
  actionLoading: Actions.REPORT_RUNAWAY_LABORER_ASYNC,
  actionSuccess: Actions.REPORT_RUNAWAY_LABORER_SUCCESS,
  actionFail: Actions.REPORT_RUNAWAY_LABORER_FAIL,
  callApiFn: evisaAPI.reportRunawayLaborer,
})

function* createAgent(action: ReturnType<typeof createAgentAction>) {
  const removeRequest = getSimpleRequestHandler<typeof evisaAPI.createAgent, ReturnType<typeof createAgentAction>>({
    actionLoading: Actions.CREATE_AGENT_ASYNC,
    actionSuccess: Actions.CREATE_AGENT_SUCCESS,
    actionFail: Actions.CREATE_AGENT_FAIL,
    callApiFn: evisaAPI.createAgent,
  })
  const { status } = yield call(removeRequest, action)
  if (status.success) {
    yield call(getAuthData, action)
  }
}

const verifyAgent = getSimpleRequestHandler<typeof evisaAPI.verifyAgent, ReturnType<typeof verifyAgentAction>>({
  actionLoading: Actions.VERIFY_AGENT_ASYNC,
  actionSuccess: Actions.VERIFY_AGENT_SUCCESS,
  actionFail: Actions.VERIFY_AGENT_FAIL,
  callApiFn: evisaAPI.verifyAgent,
})

function* removeAgent(action: ReturnType<typeof removeAgentAction>) {
  const removeRequest = getSimpleRequestHandler<typeof evisaAPI.removeAgent, ReturnType<typeof removeAgentAction>>({
    actionLoading: Actions.REMOVE_AGENT_ASYNC,
    actionSuccess: Actions.REMOVE_AGENT_SUCCESS,
    actionFail: Actions.REMOVE_AGENT_FAIL,
    callApiFn: evisaAPI.removeAgent,
  })
  const { status } = yield call(removeRequest, action)
  if (status.success) {
    yield call(getAuthData, action)
  }
}

const cancelReportRunawayLaborer = getSimpleRequestHandler<
  typeof evisaAPI.cancelReportRunawayLaborer,
  ReturnType<typeof cancelReportRunawayLaborerAction>
>({
  actionLoading: Actions.CANCEL_REPORT_RUNAWAY_LABORER_ASYNC,
  actionSuccess: Actions.CANCEL_REPORT_RUNAWAY_LABORER_SUCCESS,
  actionFail: Actions.CANCEL_REPORT_RUNAWAY_LABORER_FAIL,
  callApiFn: evisaAPI.cancelReportRunawayLaborer,
})

function* getDefaultArrivalCity(action: ReturnType<typeof getDefaultArrivalCityAction>) {
  const getDefaultArrivalCityApiCall = getSimpleRequestHandler<
    typeof evisaAPI.getDefaultArrivalCity,
    ReturnType<typeof getDefaultArrivalCityAction>
  >({
    callApiFn: evisaAPI.getDefaultArrivalCity,
  })
  const { response }: { response: GetDefaultArrivalCityResponse } = yield getDefaultArrivalCityApiCall(action)
  const arrivalCityId = response.city?.id ? parseInt(response.city?.id, 10) : DEFAULT_ARRIVAL_CITY_ID

  yield put(setMarketFilter({ arrivalCityId }))
  yield put(setVipRecruitmentFilter({ arrivalCityId }))
  yield put(setRecruitmentFilter({ arrivalCityId }))
}

const getWorkersList = getSimpleRequestHandler<
  typeof evisaAPI.getWorkersList,
  ReturnType<typeof getWorkersListAction>
>({
  actionLoading: Actions.GET_WORKERS_ASYNC,
  actionSuccess: Actions.GET_WORKERS_SUCCESS,
  actionFail: Actions.GET_WORKERS_FAIL,
  callApiFn: evisaAPI.getWorkersList,
})

const getEligibleWorkers = getSimpleRequestHandler<
  typeof evisaAPI.getWorkersList,
  ReturnType<typeof getEligibleWorkersAction>
>({
  actionLoading: Actions.GET_ELIGIBLE_WORKERS_ASYNC,
  actionSuccess: Actions.GET_ELIGIBLE_WORKERS_SUCCESS,
  actionFail: Actions.GET_ELIGIBLE_WORKERS_FAIL,
  callApiFn: evisaAPI.getEligibleWorkers,
})

const getLocationDetails = getSimpleRequestHandler<
  typeof evisaAPI.getLocationDetails,
  ReturnType<typeof getLocationDetailsAction>
>({
  actionLoading: Actions.GET_LOCATION_DETAILS_ASYNC,
  actionSuccess: Actions.GET_LOCATION_DETAILS_SUCCESS,
  actionFail: Actions.GET_LOCATION_DETAILS_FAIL,
  callApiFn: evisaAPI.getLocationDetails,
})

function* saveUserData(action: ReturnType<typeof saveUserDataAction>) {
  try {
    yield putLoading({ type: Actions.SAVE_USER_DATA_ASYNC, payload: {} })
    yield all([
      call(evisaAPI.updateUserPersonalInfo, action.payload.params, {}, { silenceError: false }),
      call(evisaAPI.updateUserContacts, action.payload.params, {}, { silenceError: false }),
    ])
    const response: Await<ReturnType<typeof evisaAPI.getUserProfile>> = yield call(
      evisaAPI.getUserProfile,
      {},
      {},
      { silenceError: false },
    )
    authManager.setAuthData({
      userData: convertUserProfileResponse(response.response as GetUserProfileResponse),
    })
    yield putSuccess({
      type: Actions.SAVE_USER_DATA_SUCCESS,
      payload: {},
    })
  } catch (error) {
    const response = (error as Await<CallApiFnResponse>).response as ValidationErrors | undefined
    yield putFail({
      type: Actions.SAVE_USER_DATA_FAIL,
      payload: {
        errorMessage:
          getFilteredIbanValidationMessage(response?.errors) ||
          (response as GeneralFailResponse | undefined)?.error?.message ||
          '',
      },
    })
  }
}

function* updateUserPersonalInfo(action: ReturnType<typeof updatePersonalDetailsAction>) {
  const updateRequest = getSimpleRequestHandler<
    typeof evisaAPI.updateUserPersonalInfo,
    ReturnType<typeof updatePersonalDetailsAction>
  >({
    actionLoading: Actions.UPDATE_PERSONAL_INFO_ASYNC,
    actionSuccess: Actions.UPDATE_PERSONAL_INFO_SUCCESS,
    actionFail: Actions.UPDATE_PERSONAL_INFO_FAIL,
    callApiFn: evisaAPI.updateUserPersonalInfo,
  })

  const { status, response } = yield call(updateRequest, action)
  if (status.success) {
    try {
      const userProfileResponse: Await<ReturnType<typeof evisaAPI.getUserProfile>> = yield call(
        evisaAPI.getUserProfile,
        {},
        {},
        { silenceError: false },
      )
      authManager.setAuthData({
        userData: convertUserProfileResponse(userProfileResponse.response as GetUserProfileResponse),
      })
    } catch (e) {
      console.warn(`set user profile error: ${e}`)
    }
  } else {
    yield putFail({
      type: Actions.UPDATE_PERSONAL_INFO_FAIL,
      payload: {
        errorMessage:
          getFilteredIbanValidationMessage(response?.errors) || formatValidationMessages(response?.errors) || '',
      },
    })
  }
}

function* updateUserContacts(action: ReturnType<typeof updateUserContactsAction>) {
  const updateRequest = getSimpleRequestHandler<
    typeof evisaAPI.updateUserContacts,
    ReturnType<typeof updateUserContactsAction>
  >({
    actionLoading: Actions.UPDATE_USER_CONTACTS_ASYNC,
    actionSuccess: Actions.UPDATE_USER_CONTACTS_SUCCESS,
    actionFail: Actions.UPDATE_USER_CONTACTS_FAIL,
    callApiFn: evisaAPI.updateUserContacts,
  })

  const { status, response } = yield call(updateRequest, action)
  if (status.success) {
    try {
      const userProfileResponse: Await<ReturnType<typeof evisaAPI.getUserProfile>> = yield call(
        evisaAPI.getUserProfile,
        {},
        {},
        { silenceError: false },
      )
      authManager.setAuthData({
        userData: convertUserProfileResponse(userProfileResponse.response as GetUserProfileResponse),
      })
    } catch (e) {
      console.warn(`set user profile error: ${e}`)
    }
  } else {
    yield putFail({
      type: Actions.UPDATE_PERSONAL_INFO_FAIL,
      payload: {
        errorMessage: (response as GeneralFailResponse | undefined)?.error?.message,
      },
    })
  }
}

const changePassword = getSimpleRequestHandler<
  typeof evisaAPI.changePassword,
  ReturnType<typeof changePasswordAction>
>({
  actionLoading: Actions.CHANGE_PASSWORD_ASYNC,
  actionSuccess: Actions.CHANGE_PASSWORD_SUCCESS,
  actionFail: Actions.CHANGE_PASSWORD_FAIL,
  callApiFn: evisaAPI.changePassword,
})

const getVisaIssuePlaces = getSimpleRequestHandler<
  typeof evisaAPI.getVisaIssuePlaces,
  ReturnType<typeof getVisaIssuePlacesAction>
>({
  actionLoading: Actions.GET_VISA_ISSUE_PLACES_ASYNC,
  actionSuccess: Actions.GET_VISA_ISSUE_PLACES_SUCCESS,
  actionFail: Actions.GET_VISA_ISSUE_PLACES_FAIL,
  callApiFn: evisaAPI.getVisaIssuePlaces,
})

const getRunaways = getSimpleRequestHandler<typeof evisaAPI.getRunaways, ReturnType<typeof getRunawaysAction>>({
  actionLoading: Actions.GET_RUNAWAYS_ASYNC,
  actionSuccess: Actions.GET_RUNAWAYS_SUCCESS,
  actionFail: Actions.GET_RUNAWAYS_FAIL,
  callApiFn: evisaAPI.getRunaways,
})

const getReplaceableVisa = getSimpleRequestHandler<
  typeof evisaAPI.getReplaceableVisa,
  ReturnType<typeof getReplaceableVisaAction>
>({
  actionLoading: Actions.GET_REPLACEABLE_VISA_ASYNC,
  actionSuccess: Actions.GET_REPLACEABLE_VISA_SUCCESS,
  actionFail: Actions.GET_REPLACEABLE_VISA_FAIL,
  callApiFn: evisaAPI.getReplaceableVisa,
})

const getSkills = getSimpleRequestHandler<typeof evisaAPI.getSkills, ReturnType<typeof getSkillsAction>>({
  actionLoading: Actions.GET_SKILLS_ASYNC,
  actionSuccess: Actions.GET_SKILLS_SUCCESS,
  actionFail: Actions.GET_SKILLS_FAIL,
  callApiFn: evisaAPI.getSkills,
})

const getRejectionReasonsList = getSimpleRequestHandler<
  typeof evisaAPI.getRejectionReasonsList,
  ReturnType<typeof getRejectionReasonsListAction>
>({
  actionLoading: Actions.GET_REJECTION_REASONS_LIST_ASYNC,
  actionSuccess: Actions.GET_REJECTION_REASONS_LIST_SUCCESS,
  actionFail: Actions.GET_REJECTION_REASONS_LIST_FAIL,
  callApiFn: evisaAPI.getRejectionReasonsList,
})

const cancelVisa = getSimpleRequestHandler<typeof evisaAPI.cancelVisa, ReturnType<typeof cancelVisaAction>>({
  actionLoading: Actions.CANCEL_VISA_ASYNC,
  actionSuccess: Actions.CANCEL_VISA_SUCCESS,
  actionFail: Actions.CANCEL_VISA_FAIL,
  callApiFn: evisaAPI.cancelVisa,
})

const makeRefundSADAD = getSimpleRequestHandler<
  typeof evisaAPI.makeRefundSADAD,
  ReturnType<typeof makeRefundSADADAction>
>({
  actionLoading: Actions.MAKE_REFUND_SADAD_ASYNC,
  actionSuccess: Actions.MAKE_REFUND_SADAD_SUCCESS,
  actionFail: Actions.MAKE_REFUND_SADAD_FAIL,
  callApiFn: evisaAPI.makeRefundSADAD,
})

const makeRefundPayFort = getSimpleRequestHandler<
  typeof evisaAPI.makeRefundPayFort,
  ReturnType<typeof makeRefundPayFortAction>
>({
  actionLoading: Actions.MAKE_REFUND_PAY_FORT_ASYNC,
  actionSuccess: Actions.MAKE_REFUND_PAY_FORT_SUCCESS,
  actionFail: Actions.MAKE_REFUND_PAY_FORT_FAIL,
  callApiFn: evisaAPI.makeRefundPayFort,
})

const getRefundableBills = getSimpleRequestHandler<
  typeof evisaAPI.getRefundableBills,
  ReturnType<typeof getRefundableBillsAction>
>({
  actionLoading: Actions.GET_REFUNDABLE_BILLS_ASYNC,
  actionSuccess: Actions.GET_REFUNDABLE_BILLS_SUCCESS,
  actionFail: Actions.GET_REFUNDABLE_BILLS_FAIL,
  callApiFn: evisaAPI.getRefundableBills,
})

const checkOutdatedIBAN = getSimpleRequestHandler<
  typeof evisaAPI.checkOutdatedIBAN,
  ReturnType<typeof checkOutdatedIBANAction>
>({
  actionLoading: Actions.CHECK_OUTDATED_IBAN_ASYNC,
  actionSuccess: Actions.CHECK_OUTDATED_IBAN_SUCCESS,
  actionFail: Actions.CHECK_OUTDATED_IBAN_FAIL,
  callApiFn: evisaAPI.checkOutdatedIBAN,
})

function* getDownloadHistory(action: ReturnType<typeof getDownloadHistoryAction>) {
  const getDownloadHistoryAPICall = getSimpleRequestHandler<
    typeof evisaAPI.getDownloadHistory,
    ReturnType<typeof getDownloadHistoryAction>
  >({
    callApiFn: evisaAPI.getDownloadHistory,
  })

  const getDownloadHistoryDetailsAPICall = getSimpleRequestHandler<
    typeof evisaAPI.getDownloadHistoryDetails,
    ReturnType<typeof getDownloadHistoryDetailsAction>
  >({
    actionSuccess: Actions.GET_DOWNLOAD_HISTORY_DETAILS_SUCCESS,
    actionFail: Actions.GET_DOWNLOAD_HISTORY_DETAILS_FAIL,
    actionLoading: Actions.GET_DOWNLOAD_HISTORY_DETAILS_ASYNC,
    callApiFn: evisaAPI.getDownloadHistoryDetails,
  })

  const { response, status }: Await<ReturnType<typeof evisaAPI.getDownloadHistory>> = yield getDownloadHistoryAPICall(
    action,
  )
  if (status.fail) {
    yield putFail({ type: Actions.GET_DOWNLOAD_HISTORY_DETAILS_FAIL })
    return
  }
  yield getDownloadHistoryDetailsAPICall(
    getDownloadHistoryDetailsAction({ uploads: response as DownloadHistoryResponse }),
  )
}
const getLabors = getSimpleRequestHandler<typeof evisaAPI.getLabors, ReturnType<typeof getLaborsAction>>({
  actionLoading: Actions.GET_LABORS_ASYNC,
  actionSuccess: Actions.GET_LABORS_SUCCESS,
  actionFail: Actions.GET_LABORS_FAIL,
  callApiFn: evisaAPI.getLabors,
})

const updateLabors = getSimpleRequestHandler<typeof evisaAPI.updateLabors, ReturnType<typeof updateLaborsAction>>({
  actionLoading: Actions.UPDATE_LABORS_ASYNC,
  actionSuccess: Actions.UPDATE_LABORS_SUCCESS,
  actionFail: Actions.UPDATE_LABORS_FAIL,
  callApiFn: evisaAPI.updateLabors,
})

const submitWataniSurvey = getSimpleRequestHandler<
  typeof evisaAPI.submitWataniSurvey,
  ReturnType<typeof submitWataniSurveyAction>
>({
  actionLoading: Actions.SUBMIT_WATANI_SURVEY_ASYNC,
  actionSuccess: Actions.SUBMIT_WATANI_SURVEY_SUCCESS,
  actionFail: Actions.SUBMIT_WATANI_SURVEY_FAIL,
  callApiFn: evisaAPI.submitWataniSurvey,
})

const getEligibleLaborers = getSimpleRequestHandler<
  typeof evisaAPI.getEligibleLaborers,
  ReturnType<typeof getEligibleLaborersAction>
>({
  actionLoading: Actions.GET_ELIGIBLE_LABORERS_ASYNC,
  actionSuccess: Actions.GET_ELIGIBLE_LABORERS_SUCCESS,
  actionFail: Actions.GET_ELIGIBLE_LABORERS_FAIL,
  callApiFn: evisaAPI.getEligibleLaborers,
})

const checkFeesExemption = getSimpleRequestHandler<
  typeof evisaAPI.checkFeesExemption,
  ReturnType<typeof checkFeesExemptionAction>
>({
  actionLoading: Actions.CHECK_FEES_EXEMPTION_ASYNC,
  actionSuccess: Actions.CHECK_FEES_EXEMPTION_SUCCESS,
  actionFail: Actions.CHECK_FEES_EXEMPTION_FAIL,
  callApiFn: evisaAPI.checkFeesExemption,
})

const getOccupationDescriptions = getSimpleRequestHandler<
  typeof evisaAPI.getOccupationDescriptions,
  ReturnType<typeof getOccupationDescriptionsAction>
>({
  actionLoading: Actions.GET_OCCUPATION_DESCRIPTIONS_ASYNC,
  actionSuccess: Actions.GET_OCCUPATION_DESCRIPTIONS_SUCCESS,
  actionFail: Actions.GET_OCCUPATION_DESCRIPTIONS_FAIL,
  callApiFn: evisaAPI.getOccupationDescriptions,
})

const submitFeesExemption = getSimpleRequestHandler<
  typeof evisaAPI.submitFeesExemption,
  ReturnType<typeof submitFeesExemptionAction>
>({
  actionLoading: Actions.SUBMIT_FEES_EXEMPTION_ASYNC,
  actionSuccess: Actions.SUBMIT_FEES_EXEMPTION_SUCCESS,
  actionFail: Actions.SUBMIT_FEES_EXEMPTION_FAIL,
  callApiFn: evisaAPI.submitFeesExemption,
})

const updateNationalAddress = getSimpleRequestHandler<
  typeof evisaAPI.updateNationalAddress,
  ReturnType<typeof updateNationalAddressAction>
>({
  actionLoading: Actions.UPDATE_NATIONAL_ADDRESS_ASYNC,
  actionSuccess: Actions.UPDATE_NATIONAL_ADDRESS_SUCCESS,
  actionFail: Actions.UPDATE_NATIONAL_ADDRESS_FAIL,
  callApiFn: evisaAPI.updateNationalAddress,
})

const surveySubmit = getSimpleRequestHandler<typeof evisaAPI.surveySubmit, ReturnType<typeof surveySubmitAction>>({
  actionLoading: Actions.SUBMIT_SURVEY_ASYNC,
  actionSuccess: Actions.SUBMIT_SURVEY_SUCCESS,
  actionFail: Actions.SUBMIT_SURVEY_FAIL,
  callApiFn: evisaAPI.surveySubmit,
})

function* uploadDisabilityProof(action: ReturnType<ReturnType<typeof uploadDisabilityProofAction>>) {
  yield putLoading({ type: Actions.UPLOAD_DISABILITY_PROOF_ASYNC, callAPIActionPayload: action.payload })
  const callApiFn = evisaAPI.uploadDisabilityProof
  const { response, headers, status }: Await<ReturnType<typeof evisaAPI.uploadDisabilityProof>> = yield call<
    typeof evisaAPI.uploadDisabilityProof
  >(callApiFn, action?.payload?.params, action.payload.config)

  if (status.fail) {
    yield putFail({
      type: Actions.UPLOAD_DISABILITY_PROOF_FAIL,
      callAPIActionPayload: action.payload,
      payload: response,
      headers,
    })
    return
  }

  yield putSuccess({
    type: Actions.UPLOAD_DISABILITY_PROOF_SUCCESS,
    callAPIActionPayload: action.payload,
    payload: response,
    headers,
  })
}

function* removeFromPilotList(action: ReturnType<typeof removeFromPilotListAction>) {
  const removeRequest = getSimpleRequestHandler<
    typeof evisaAPI.removeFromPilotList,
    ReturnType<typeof removeFromPilotListAction>
  >({
    actionLoading: Actions.REMOVE_FROM_PILOT_LIST_ASYNC,
    actionSuccess: Actions.REMOVE_FROM_PILOT_LIST_SUCCESS,
    actionFail: Actions.REMOVE_FROM_PILOT_LIST_FAIL,
    callApiFn: evisaAPI.removeFromPilotList,
  })
  const { status } = yield call(removeRequest, action)
  if (status.success) {
    try {
      const userProfileResponse: Await<ReturnType<typeof evisaAPI.getUserProfile>> = yield call(
        evisaAPI.getUserProfile,
        {},
        {},
        { silenceError: false },
      )
      authManager.setAuthData({
        userData: convertUserProfileResponse(userProfileResponse.response as GetUserProfileResponse),
      })
    } catch (e) {
      console.warn(`set user profile error: ${e}`)
    }
  }
}

const setNewPrimaryAddress = getSimpleRequestHandler<
  typeof evisaAPI.setNewPrimaryAddress,
  ReturnType<typeof setNewPrimaryAddressAction>
>({
  actionLoading: Actions.SET_NEW_PRIMARY_ADDRESS_ASYNC,
  actionSuccess: Actions.SET_NEW_PRIMARY_ADDRESS_SUCCESS,
  actionFail: Actions.SET_NEW_PRIMARY_ADDRESS_FAIL,
  callApiFn: evisaAPI.setNewPrimaryAddress,
})

const authLogout = getSimpleRequestHandler<typeof evisaAPI.authLogout, ReturnType<typeof authLogoutAction>>({
  actionLoading: Actions.LOGOUT_ASYNC,
  actionSuccess: Actions.LOGOUT_SUCCESS,
  actionFail: Actions.LOGOUT_FAIL,
  callApiFn: evisaAPI.authLogout,
})

export default [
  takeLatest(Actions.GET_NATIONALITIES, getNationalities),
  takeLatest(Actions.GET_VISA_INVOICE_FILE, getVisaInvoiceFile),
  takeLatest(Actions.GET_OCCUPATIONS, getOccupations),
  takeLatest(Actions.GET_LABOR_OFFICES, getLaborOffices),
  takeLatest(Actions.SEND_ABSHER_FOR_UPDATE_MOBILE, sendAbsherForUpdatingMobile),
  takeLatest(Actions.CREATE_AGENT, createAgent),
  takeLatest(Actions.VERIFY_AGENT, verifyAgent),
  takeLatest(Actions.REMOVE_AGENT, removeAgent),
  takeLatest(Actions.VERIFY_ABSHER_FOR_UPDATE_MOBILE, verifyAbsherForUpdatingMobile),
  takeLatest(Actions.SEND_OTP_FOR_UPDATE_MOBILE, sendOtpForUpdatingMobile),
  takeLatest(Actions.VERIFY_OTP_FOR_UPDATE_MOBILE, verifyOtpForUpdatingMobile),
  takeLatest(Actions.GET_ARRIVAL_CITIES, getArrivalCities),
  takeLatest(Actions.GET_DEFAULT_ARRIVAL_CITY, getDefaultArrivalCity),
  takeLatest(Actions.GET_WORKERS, getWorkersList),
  takeLatest(Actions.GET_LOCATION_DETAILS, getLocationDetails),
  takeLatest(Actions.SAVE_USER_DATA, saveUserData),
  takeLatest(Actions.CHANGE_PASSWORD, changePassword),
  takeLatest(Actions.GET_VISA_ISSUE_PLACES, getVisaIssuePlaces),
  takeLatest(Actions.GET_ELIGIBLE_WORKERS, getEligibleWorkers),
  takeLatest(Actions.GET_REPLACEABLE_VISA, getReplaceableVisa),
  takeLatest(Actions.GET_SKILLS, getSkills),
  takeLatest(Actions.GET_REJECTION_REASONS_LIST, getRejectionReasonsList),
  takeLatest(Actions.CANCEL_VISA, cancelVisa),
  takeLatest(Actions.MAKE_REFUND_SADAD, makeRefundSADAD),
  takeLatest(Actions.MAKE_REFUND_PAY_FORT, makeRefundPayFort),
  takeLatest(Actions.GET_REFUNDABLE_BILLS, getRefundableBills),
  takeLatest(Actions.CHECK_OUTDATED_IBAN, checkOutdatedIBAN),
  takeLatest(Actions.GET_DOWNLOAD_HISTORY, getDownloadHistory),
  takeLatest(Actions.GET_LABORS, getLabors),
  takeLatest(Actions.UPDATE_LABORS, updateLabors),
  takeLeading(Actions.SUBMIT_WATANI_SURVEY, submitWataniSurvey),
  takeLeading(Actions.GET_OCCUPATION_DESCRIPTIONS, getOccupationDescriptions),
  takeLeading(Actions.UPDATE_PERSONAL_INFO, updateUserPersonalInfo),
  takeLeading(Actions.UPDATE_USER_CONTACTS, updateUserContacts),
  takeLeading(Actions.SUBMIT_SURVEY, surveySubmit),
  takeLeading(Actions.SUBMIT_FEES_EXEMPTION, submitFeesExemption),
  takeLeading(Actions.REMOVE_FROM_PILOT_LIST, removeFromPilotList),
  takeLeading(Actions.GET_ELIGIBLE_LABORERS, getEligibleLaborers),
  takeLeading(Actions.CHECK_FEES_EXEMPTION, checkFeesExemption),
  takeEvery(Actions.UPLOAD_DISABILITY_PROOF, uploadDisabilityProof),
  takeLeading(Actions.GET_RUNAWAYS, getRunaways),
  takeLeading(Actions.REPORT_RUNAWAY_LABORER, reportRunawayLaborer),
  takeLeading(Actions.CANCEL_REPORT_RUNAWAY_LABORER, cancelReportRunawayLaborer),
  takeLeading(Actions.UPDATE_NATIONAL_ADDRESS, updateNationalAddress),
  takeLeading(Actions.SET_NEW_PRIMARY_ADDRESS, setNewPrimaryAddress),
  takeLeading(Actions.LOGOUT, authLogout),
]
