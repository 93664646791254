import { GetListingRequestListResponse, ListingRequestFromBE } from 'api/contractAuthAPI'
import { CallAPIAction, Status } from 'api/types'
import { ListingRequest } from 'common/types/commonTypes'
import { getListingRequestList } from 'redux/actionCreators/contractAuthAPI'
import Actions from 'redux/actions'

type State = {
  data: Array<ListingRequest>
  params: {
    page: number
    lastPage: number
    requestStatus?: Array<string>
    offerStatus?: Array<string>
    requestType?: string
  }
} & Status

const initialState: State = {
  isLoading: false,
  success: false,
  fail: false,
  data: [],
  params: {
    page: 0,
    lastPage: 0,
    requestStatus: ['ALL'],
    offerStatus: undefined,
    requestType: '',
  },
}

const convertToState = (item: ListingRequestFromBE): ListingRequest => ({
  id: item.id,
  name: {
    id: 0,
    label: item.name,
    labelEn: item.name_en,
  },
  iqamaNumber: item.iqama_number,
  occupation: {
    id: 0,
    label: item.occupation.label,
    labelEn: item.occupation.label_en,
  },
  nationality: {
    id: 0,
    label: item.nationality.label,
    labelEn: item.nationality.label_en,
  },
  isOwner: item.is_owner,
  status: item.status,
  expireAt: item.expire_at,
  createdAt: item.created_at,
  sentOffers: item.sent_offers
    ? item.sent_offers.map((offer) => ({
        id: offer.id,
        status: offer.status,
        updatedAt: offer.updated_at,
      }))
    : [],
  receivedOffers: item.received_offers
    ? item.received_offers.map((offer) => ({
        id: offer.id,
        status: offer.status,
        updatedAt: offer.updated_at,
      }))
    : [],
})

export default (
  state: State = initialState,
  action: CallAPIAction<
    Actions.GET_LISTING_REQUEST_LIST_ASYNC,
    Actions.GET_LISTING_REQUEST_LIST_SUCCESS,
    Actions.GET_LISTING_REQUEST_LIST_FAIL,
    GetListingRequestListResponse,
    {},
    {},
    ReturnType<typeof getListingRequestList>['payload']
  >,
) => {
  switch (action.type) {
    case Actions.GET_LISTING_REQUEST_LIST_ASYNC: {
      const { params } = action.callAPIActionPayload

      return {
        ...state,
        ...action.status,
        params: {
          page: params.page || 0,
          requestStatus: params.requestStatus || (!params.offerStatus ? ['ALL'] : undefined),
          offerStatus: params.offerStatus,
          requestType: params.requestType || '',
          lastPage: params.requestStatus !== state.params.requestStatus ? 0 : state.params.lastPage,
        },
      }
    }

    case Actions.GET_LISTING_REQUEST_LIST_SUCCESS: {
      const { params } = action.callAPIActionPayload

      return {
        ...state,
        ...action.status,
        data: action.payload.data.map(convertToState),
        params: {
          page: params.page || 0,
          requestStatus: params.requestStatus || (!params.offerStatus ? ['ALL'] : undefined),
          offerStatus: params.offerStatus,
          requestType: params.requestType || '',
          lastPage: action.payload.meta.last_page,
        },
      }
    }

    case Actions.GET_LISTING_REQUEST_LIST_FAIL:
      return {
        ...state,
        ...action.status,
      }
    default:
      return state
  }
}
