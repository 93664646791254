import { Status, CallAPIAction } from 'api/types'
import Actions from 'redux/actions'

type State = {
  errorMessage: string
} & Status

const initialState = {
  errorMessage: '',
  isLoading: false,
  success: false,
  fail: false,
}

type FailResponse = {
  error?: {
    message?: string
  }
}

export default (
  state: State = initialState,
  action: CallAPIAction<
    Actions.EXTEND_LISTING_REQUEST_ASYNC,
    Actions.EXTEND_LISTING_REQUEST_SUCCESS,
    Actions.EXTEND_LISTING_REQUEST_FAIL,
    {},
    FailResponse
  >,
): State => {
  switch (action.type) {
    case Actions.EXTEND_LISTING_REQUEST_ASYNC:
    case Actions.EXTEND_LISTING_REQUEST_SUCCESS: {
      return {
        ...state,
        ...action.status,
      }
    }

    case Actions.EXTEND_LISTING_REQUEST_FAIL: {
      return {
        ...state,
        ...action.status,
        errorMessage: action.payload?.error?.message || '',
      }
    }

    default:
      return state
  }
}
