import { CallAPIAction, Status } from 'api/types'
import Actions from 'redux/actions'
import { GetEmployerLaborersListResponse, LaborerFromListFromBE } from 'api/contractAuthAPI'
import { EmployerLaborerInfo } from 'common/types/transformedRespTypes'

type State = {
  data: Array<EmployerLaborerInfo>
  lastPage: number
} & Status

const initialState = {
  data: [],
  lastPage: 0,
  isLoading: false,
  success: false,
  fail: false,
}

const convertResponseToState = (resp: LaborerFromListFromBE) => ({
  id: resp.id,
  name: {
    id: 0,
    label: resp.name,
    labelEn: resp.name_en,
  },
  iqamaNumber: resp.iqama_number,
  borderNumber: resp.border_number,
  occupation: resp.occupation && {
    id: resp.occupation.id,
    label: resp.occupation.label,
    labelEn: resp.occupation.label_en,
  },
  nationality: resp.nationality && {
    id: resp.nationality.id,
    label: resp.nationality.label,
    labelEn: resp.nationality.label_en,
  },
  salary: resp.salary,
  mobileNumber: resp.mobile_number,
  sponsor: {
    name: resp.sponsor.name,
    nameEn: resp.sponsor.name_en,
  },
  laborerProfile: resp.laborer_profile,
  status: resp.status,
  gender: resp.gender,
  birthDate: resp.birth_date,
  religion: resp.religion,
  martialStatus: resp.martial_status,
  entryDate: resp.entry_date,
  visaId: resp.visa_id,
  contract: resp.contract && {
    id: resp.contract.id,
    relatableId: resp.contract.relatable_id,
    relatableSource: resp.contract.relatable_source,
    startDate: resp.contract.start_date,
    status: resp.contract.status,
  },
  expiryDate: resp.iqama_expiry_date,
})

export default (
  state: State = initialState,
  action: CallAPIAction<
    Actions.GET_EMPLOYER_LABORERS_LIST_ASYNC,
    Actions.GET_EMPLOYER_LABORERS_LIST_SUCCESS,
    Actions.GET_EMPLOYER_LABORERS_LIST_FAIL,
    GetEmployerLaborersListResponse
  >,
): State => {
  switch (action.type) {
    case Actions.GET_EMPLOYER_LABORERS_LIST_ASYNC:
    case Actions.GET_EMPLOYER_LABORERS_LIST_FAIL: {
      return {
        ...state,
        ...action.status,
      }
    }

    case Actions.GET_EMPLOYER_LABORERS_LIST_SUCCESS: {
      return {
        ...state,
        ...action.status,
        data: action.payload.data.map(convertResponseToState),
        lastPage: action.payload.meta.last_page,
      }
    }

    default:
      return state
  }
}
