import { GetListingRequestsResponse } from 'api/laborerPortalAPI/marketplace'
import { CallAPIAction, Status } from 'api/types'
import { ListingCVRequest } from 'common/types/commonTypes'
import { convertListingCVRequestToState } from 'common/utils/listingRequestHelper'
import Actions from 'redux/actions'

type State = {
  params: {
    page: number
    lastPage: number
  }
  data: Array<ListingCVRequest>
} & Status

const initialState: State = {
  params: {
    page: 0,
    lastPage: 0,
  },
  data: [],
  isLoading: false,
  success: false,
  fail: false,
}

export default (
  state: State = initialState,
  action: CallAPIAction<
    Actions.GET_LABORER_LISTING_REQUESTS_ASYNC,
    Actions.GET_LABORER_LISTING_REQUESTS_SUCCESS,
    Actions.GET_LABORER_LISTING_REQUESTS_FAIL,
    GetListingRequestsResponse
  >,
) => {
  switch (action.type) {
    case Actions.GET_LABORER_LISTING_REQUESTS_SUCCESS:
      return {
        ...state,
        ...action.status,
        data: action.payload.data.map(convertListingCVRequestToState),
        params: {
          page: action.payload.meta.current_page - 1,
          lastPage: action.payload.meta.last_page,
        },
      }
    case Actions.GET_LABORER_LISTING_REQUESTS_ASYNC:
    case Actions.GET_LABORER_LISTING_REQUESTS_FAIL:
      return {
        ...state,
        ...action.status,
      }
    default:
      return state
  }
}
