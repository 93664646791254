import { AcceptListingOfferResponse } from 'api/contractAuthAPI'
import { CallAPIAction, Status } from 'api/types'
import Actions from 'redux/actions'

type State = { transferId: number } & Status

const initialState: State = {
  transferId: 0,
  isLoading: false,
  success: false,
  fail: false,
}

export default (
  state: State = initialState,
  action: CallAPIAction<
    Actions.ACCEPT_LISTING_OFFER_ASYNC,
    Actions.ACCEPT_LISTING_OFFER_SUCCESS,
    Actions.ACCEPT_LISTING_OFFER_FAIL,
    AcceptListingOfferResponse
  >,
): State => {
  switch (action.type) {
    case Actions.ACCEPT_LISTING_OFFER_ASYNC:
    case Actions.ACCEPT_LISTING_OFFER_FAIL: {
      return {
        ...state,
        ...action.status,
      }
    }

    case Actions.ACCEPT_LISTING_OFFER_SUCCESS: {
      return {
        ...state,
        ...action.status,
        transferId: action.payload.data.transfer_id,
      }
    }

    default:
      return state
  }
}
