import { ResubmitListingRequestResponse } from 'api/contractAuthAPI'
import { Status, CallAPIAction } from 'api/types'
import Actions from 'redux/actions'

type State = {
  id: number
  errorMessage: string
} & Status

const initialState = {
  id: 0,
  errorMessage: '',
  isLoading: false,
  success: false,
  fail: false,
}

type FailResponse = {
  message?: string
}

export default (
  state: State = initialState,
  action: CallAPIAction<
    Actions.RESUBMIT_LISTING_REQUEST_ASYNC,
    Actions.RESUBMIT_LISTING_REQUEST_SUCCESS,
    Actions.RESUBMIT_LISTING_REQUEST_FAIL,
    ResubmitListingRequestResponse,
    FailResponse
  >,
): State => {
  switch (action.type) {
    case Actions.RESUBMIT_LISTING_REQUEST_ASYNC: {
      return {
        ...state,
        ...action.status,
      }
    }

    case Actions.RESUBMIT_LISTING_REQUEST_SUCCESS: {
      return {
        ...state,
        ...action.status,
        id: action.payload.data.id,
      }
    }

    case Actions.RESUBMIT_LISTING_REQUEST_FAIL: {
      return {
        ...state,
        ...action.status,
        errorMessage: action.payload?.message || '',
      }
    }

    default:
      return state
  }
}
