import { AxiosRequestConfig } from 'axios'

import { LIST_ITEMS_PER_PAGE } from 'common/constants'
import {
  FarmerVisaIssueRequestStatus,
  FarmerVisaStatus,
  PaginatorBE,
  FarmerEligibilityErrorMessages,
  PaymentMethods,
  BillStatus,
  GeneralFailResponse,
  FarmerAgriculturalRequestErrorMessages,
} from 'common/types/commonTypes'
import {
  NationalityFromBE,
  ObjectWithTranslationFromBE,
  OccupationFromBE,
  PayfortBill,
} from 'common/types/transformedRespTypes'

import { callAPI, evisaAPIWithCredentials } from '../axios'

export type GetFarmerVisaRequestsParams = {
  page: number
  status: FarmerVisaIssueRequestStatus
  perPage?: number
}

export type FarmerVisaIssueRequestFromBE = {
  id: number
  status: FarmerVisaIssueRequestStatus
  created_at: string
  occupation: OccupationFromBE
  nationality: NationalityFromBE
  visaIssuePlace: ObjectWithTranslationFromBE
  rejectionReasons?: Array<ObjectWithTranslationFromBE>
  farmerVisa: {
    id: number
    border_number: string
    created_at: string
    followup_number: string
    followup_date: string
    status: FarmerVisaStatus
    user: {
      name: string
      id_number: number
    }
    pdf_id?: string
  }
  bill: {
    amount: string
    bill_number: string
    created_at: string
    id: string
    paid_at: string
    payment_method: PaymentMethods // we can't have SADAD option here
    status: BillStatus
    vat: string
    tbs_id?: string
  } | null
}

export type GetFarmerVisaRequestsResponse = {
  paginator: PaginatorBE<FarmerVisaIssueRequestFromBE>
}

const getFarmerVisaRequests = (params: GetFarmerVisaRequestsParams, config: AxiosRequestConfig) =>
  evisaAPIWithCredentials.get<GetFarmerVisaRequestsResponse>('app/farmerVisaRequests', {
    params: { page: params.page + 1, status: params.status, per_page: params.perPage || LIST_ITEMS_PER_PAGE },
    ...config,
  })

type CreditFromBE = {
  CreditId: string
  AvailableCredit: string
  ContractExpiryDate: string
  EstablishmentName: string
  occupations: Array<OccupationFromBE>
}

export type CheckEligibilityForFarmerVisaResponse = {
  credits: Array<CreditFromBE>
  unallowed_nationalities: Array<number>
}

export type CheckEligibilityForFarmerVisaFailResponse = {
  error: {
    code: number
    message: FarmerEligibilityErrorMessages | string
  }
}

const checkEligibilityForFarmerVisa = (body = {}, config: AxiosRequestConfig) =>
  evisaAPIWithCredentials.post<CheckEligibilityForFarmerVisaResponse>(
    'app/farmerVisaRequests/check_eligibility',
    body,
    config,
  )

export type IssueNewFarmerVisaRequestBody = {
  nationality: number | string
  occupation: number | string
  visaIssuePlace: number | string
  creditId: number | string
}

export type IssueNewFarmerVisaRequestResponse = {
  farmerVisaRequest: {
    bill: {
      status: BillStatus
    } | null
    id: number
  }
}

export type IssueNewFarmerVisaRequestFailResponse = {
  error?: {
    message: string
  }
}

const issueNewFarmerVisaRequest = (body: IssueNewFarmerVisaRequestBody, config: AxiosRequestConfig) =>
  evisaAPIWithCredentials.post<IssueNewFarmerVisaRequestResponse>(
    'app/farmerVisaRequests',
    {
      visa_issue_place: body.visaIssuePlace,
      nationality: body.nationality,
      occupation: body.occupation,
      credit_id: body.creditId,
    },
    config,
  )

export type GetInfoAboutFarmerRequestParams = {
  requestId: number | string
  unissued?: number
}

export type GetInfoAboutFarmerRequestResponse = {
  farmerVisaRequest: FarmerVisaIssueRequestFromBE
  has_refundable_bill?: boolean
}

const getInfoAboutFarmerRequest = (
  { requestId, unissued = 1 }: GetInfoAboutFarmerRequestParams,
  config: AxiosRequestConfig,
) =>
  evisaAPIWithCredentials.get<GetInfoAboutFarmerRequestResponse>(`app/farmerVisaRequests/${requestId}`, {
    params: { unissued },
    ...config,
  })

export type PayForFarmerVisaBody = {
  requestId: number
  cardType: PaymentMethods
}

export type PayForFarmerVisaResponse = {
  status: boolean
  bill: PayfortBill
}

const payForFarmerVisa = (body: PayForFarmerVisaBody, config: AxiosRequestConfig) =>
  evisaAPIWithCredentials.post<PayForFarmerVisaResponse>(
    `app/farmerVisaRequests/${body.requestId}/payfort_bill/${body.cardType}`,
    {
      channel: 1,
    },
    config,
  )

export type CancelFarmerVisaBody = {
  visaId: number
  reasonId: number
}

export type CancelFarmerVisaResponse = {}

const cancelFarmerVisa = (body: CancelFarmerVisaBody, config: AxiosRequestConfig) =>
  evisaAPIWithCredentials.post<CancelFarmerVisaResponse>(
    '/app/farmerVisa/cancel',
    {
      visa_id: body.visaId,
      reason_id: body.reasonId,
    },
    config,
  )

export type CheckAgriculturalFileBody = {
  registryNumber: string | number
  isVip?: boolean
}

export type CheckAgriculturalFileResponse = {
  farm_types?: Array<{
    id: string
    label: string
  }>
  registrationFile: {
    RegistrationNumber: string
    StartDate: string
    EndDatetDate: string
    Region: string
    City: string
    Village: string
    FarmType: string
    FarmName: string
    Location: {
      Longitude: string
      Latitude: string
    }
    Owner: {
      IdNumber: string
      FullName: string
    }
  }
  token: string
}

const checkAgriculturalFile = (body: CheckAgriculturalFileBody, config: AxiosRequestConfig) =>
  evisaAPIWithCredentials.post<CheckAgriculturalFileResponse>(
    '/app/agriculture_file/check_registration',
    {
      registry_number: body.registryNumber,
    },
    config,
  )

export type SubmitAgriculturalFileBody = {
  farmType?: number
  telephone: string
  laborOffice: number
  token: string
}

export type SubmitAgriculturalFileResponse = {
  file_number: string
}

const submitAgriculturalFile = (body: SubmitAgriculturalFileBody, config: AxiosRequestConfig) =>
  evisaAPIWithCredentials.post<SubmitAgriculturalFileResponse>(
    '/app/agriculture_file/submit',
    {
      ...(body.farmType ? { farm_type: body.farmType } : {}),
      telephone: body.telephone,
      labor_office: body.laborOffice,
      token: body.token,
    },
    config,
  )

export default {
  getFarmerVisaRequests: callAPI<typeof getFarmerVisaRequests, GetFarmerVisaRequestsResponse>(getFarmerVisaRequests),
  checkEligibilityForFarmerVisa: callAPI<
    typeof checkEligibilityForFarmerVisa,
    CheckEligibilityForFarmerVisaResponse,
    CheckEligibilityForFarmerVisaFailResponse
  >(checkEligibilityForFarmerVisa),
  issueNewFarmerVisaRequest: callAPI<
    typeof issueNewFarmerVisaRequest,
    IssueNewFarmerVisaRequestResponse,
    IssueNewFarmerVisaRequestFailResponse
  >(issueNewFarmerVisaRequest),
  getInfoAboutFarmerRequest: callAPI<typeof getInfoAboutFarmerRequest, GetInfoAboutFarmerRequestResponse>(
    getInfoAboutFarmerRequest,
  ),
  payForFarmerVisa: callAPI<typeof payForFarmerVisa, PayForFarmerVisaResponse>(payForFarmerVisa),
  cancelFarmerVisa: callAPI<typeof cancelFarmerVisa, CancelFarmerVisaResponse, GeneralFailResponse>(cancelFarmerVisa),
  checkAgriculturalFile: callAPI<typeof checkAgriculturalFile, CheckAgriculturalFileResponse, GeneralFailResponse>(
    checkAgriculturalFile,
  ),
  submitAgriculturalFile: callAPI<
    typeof submitAgriculturalFile,
    SubmitAgriculturalFileResponse,
    GeneralFailResponse<FarmerAgriculturalRequestErrorMessages>
  >(submitAgriculturalFile),
}
