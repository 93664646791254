import { GetInterviewResultResponse } from 'api/interviewAPI'
import { CallAPIAction, Status } from 'api/types'
import Actions from 'redux/actions'

type State = {
  data: {
    status: string
    invitationId: string
    invitedOn: string
    completedOn: string
    candidate: {
      name: string
      nationality: string
    }
    answers: Array<{
      question: string
      answer: string
      link: string
    }>
    targetProfile: Array<{
      title: string
      description: string
      performance: number
      proficiency: number
    }>
    softSkills: Array<{
      title: string
      value: number
    }>
  }
} & Status

const initialState: State = {
  data: {
    status: '',
    invitationId: '',
    invitedOn: '',
    completedOn: '',
    candidate: {
      name: '',
      nationality: '',
    },
    answers: [],
    targetProfile: [],
    softSkills: [],
  },
  isLoading: false,
  success: false,
  fail: false,
}

export default (
  state: State = initialState,
  action: CallAPIAction<
    Actions.GET_INTERVIEW_RESULT_ASYNC,
    Actions.GET_INTERVIEW_RESULT_SUCCESS,
    Actions.GET_INTERVIEW_RESULT_FAIL,
    GetInterviewResultResponse
  >,
) => {
  switch (action.type) {
    case Actions.GET_INTERVIEW_RESULT_SUCCESS:
      return {
        ...state,
        ...action.status,
        data: {
          status: action.payload.status,
          invitationId: action.payload.invitation_id,
          invitedOn: action.payload.invited_on,
          completedOn: action.payload.completed_on,
          candidate: {
            name: `${action.payload.data.candidate.first_name} ${action.payload.data.candidate.first_name}`,
            nationality: action.payload.data.candidate.nationality,
          },
          answers: action.payload.data.answers.map((item) => ({
            question: item.question,
            answer: item.answer,
            link: item.answer_link,
          })),
          targetProfile: Object.entries(action.payload.data.target_profile).map(([title, details]) => ({
            title,
            description: details.description,
            performance: details.value,
            proficiency: details.candidate_score,
          })),
          softSkills: Object.entries(action.payload.data.softSkills).map(([title, details]) => ({
            title,
            value: details.value,
          })),
        },
      }
    case Actions.GET_INTERVIEW_RESULT_ASYNC:
    case Actions.GET_INTERVIEW_RESULT_FAIL:
      return {
        ...state,
        ...action.status,
      }
    default:
      return state
  }
}
