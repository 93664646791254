import { GetPassportCopyResponse } from 'api/interviewAPI'
import { CallAPIAction, Status } from 'api/types'
import Actions from 'redux/actions'

type State = {
  passportCopy: Blob | null
} & Status

const initialState: State = {
  passportCopy: null,
  isLoading: false,
  success: false,
  fail: false,
}

export default (
  state: State = initialState,
  action: CallAPIAction<
    Actions.GET_PASSPORT_COPY_ASYNC,
    Actions.GET_PASSPORT_COPY_SUCCESS,
    Actions.GET_PASSPORT_COPY_FAIL,
    GetPassportCopyResponse
  >,
) => {
  switch (action.type) {
    case Actions.GET_PASSPORT_COPY_SUCCESS:
      return {
        ...state,
        ...action.status,
        passportCopy: action.payload,
      }
    case Actions.GET_PASSPORT_COPY_ASYNC:
    case Actions.GET_PASSPORT_COPY_FAIL:
      return {
        ...state,
        ...action.status,
      }
    default:
      return state
  }
}
