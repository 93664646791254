import Actions from 'redux/actions'
import type { CallAPIAction, Status } from 'api/types'
import { UploadPassportCopyResponse } from 'api/interviewAPI'

type State = {
  fileKey: string
} & Status

const initialState = {
  fileKey: '',
  isLoading: false,
  success: false,
  fail: false,
}

export default (
  state: State = initialState,
  action: CallAPIAction<
    Actions.UPLOAD_PASSPORT_COPY_ASYNC,
    Actions.UPLOAD_PASSPORT_COPY_SUCCESS,
    Actions.UPLOAD_PASSPORT_COPY_FAIL,
    UploadPassportCopyResponse
  >,
): State => {
  switch (action.type) {
    case Actions.UPLOAD_PASSPORT_COPY_ASYNC:
    case Actions.UPLOAD_PASSPORT_COPY_FAIL: {
      return {
        ...state,
        ...action.status,
      }
    }

    case Actions.UPLOAD_PASSPORT_COPY_SUCCESS: {
      return {
        ...state,
        ...action.status,
        fileKey: action.payload.key,
      }
    }

    default:
      return state
  }
}
