import { CallAPIAction, Status } from 'api/types'
import Actions from 'redux/actions'
import { GetInterviewsListResponse } from 'api/interviewAPI'
import { LaborerInterview, LaborerInterviewFromBE } from 'common/types/transformedRespTypes'

type State = {
  data: Array<LaborerInterview>
  lastPage: number
  currentPage: number
} & Status

const initialState = {
  data: [],
  lastPage: 0,
  currentPage: 0,
  isLoading: false,
  success: false,
  fail: false,
}

export const convertResponseToState = (item: LaborerInterviewFromBE): LaborerInterview => ({
  createdAt: item.created_at,
  id: item.display_id,
  employer: {
    email: item.interview_employer.email,
    id: item.interview_employer.id_number,
    mobile: item.interview_employer.mobile,
    name: item.interview_employer.name,
  },
  laborer: {
    ageGroup: item.interview_laborer.age_group,
    mobile: item.interview_laborer.mobile,
    name: item.interview_laborer.name,
    occupation: {
      id: 0,
      code: parseInt(item.interview_laborer.occupation.code || ''),
      label: item.interview_laborer.occupation.label,
      labelEn: item.interview_laborer.occupation.label_en,
    },
    nationality: {
      id: 0,
      abbreviation: item.interview_laborer.nationality.abbreviation,
      code: item.interview_laborer.nationality.code || '',
      // countryCode: item.interview_laborer.nationality.country || '',
      label: item.interview_laborer.nationality.label,
      labelEn: item.interview_laborer.nationality.label_en,
      isOnlineContractingAllowed: false,
    },
    passport: item.interview_laborer.passport,
    passportExpiry: item.interview_laborer.passport_expiry,
    passportUpload: item.interview_laborer.passport_upload.split('/').pop() || '',
  },
  pros: item.interview_pros.map((pro) => ({
    id: pro.pro_id,
    contactNumber: pro.contact_number,
    email: pro.email,
    name: pro.name,
    unifiedId: pro.unified_id,
  })),
  invitations: item.invitations.map((invitation) => ({
    createdAt: invitation.created_at,
    link: invitation.link,
    status: invitation.status,
  })),
  bill: item.bill
    ? {
        amount: item.bill.amount,
        id: item.bill.id,
        uuid: item.bill.uuid,
      }
    : undefined,
  status: item.status,
  updatedAt: item.updated_at,
})

export default (
  state: State = initialState,
  action: CallAPIAction<
    Actions.GET_LABORER_INTERVIEWS_LIST_ASYNC,
    Actions.GET_LABORER_INTERVIEWS_LIST_SUCCESS,
    Actions.GET_LABORER_INTERVIEWS_LIST_FAIL,
    GetInterviewsListResponse
  >,
): State => {
  switch (action.type) {
    case Actions.GET_LABORER_INTERVIEWS_LIST_ASYNC:
    case Actions.GET_LABORER_INTERVIEWS_LIST_FAIL: {
      return {
        ...state,
        ...action.status,
      }
    }
    case Actions.GET_LABORER_INTERVIEWS_LIST_SUCCESS: {
      return {
        ...state,
        ...action.status,
        data: action.payload.data.map(convertResponseToState),
        lastPage: action.payload.meta.last_page,
        currentPage: action.payload.meta.current_page,
      }
    }

    default:
      return state
  }
}
