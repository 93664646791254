import { AxiosRequestConfig } from 'axios'

import { NoticeStatus, PaymentMethods, UserEligibilityErrorMessages } from 'common/types/commonTypes'
import {
  NationalityFromBE,
  OccupationFromBE,
  ObjectWithTranslationFromBE,
  PayfortBill,
  NoticeBillFromBE,
} from 'common/types/transformedRespTypes'
import { LIST_ITEMS_PER_PAGE } from 'common/constants'

import { callAPI, evisaAPIWithCredentials } from '../axios'

export type NoticeFromBE = {
  id: number
  created_at: string
  occupation: OccupationFromBE
  employee: {
    nationality: NationalityFromBE
  }
  hash: string
  status: NoticeStatus
  bill: NoticeBillFromBE | null
}

export type GetNoticesListParams = {
  page?: number
  perPage?: number
  status?: NoticeStatus
}

export type GetNoticesListResponse = {
  paginator: {
    data: Array<NoticeFromBE>
    last_page: number
    current_page: number
  }
}
const getNoticesList = (params: GetNoticesListParams, config?: AxiosRequestConfig) =>
  evisaAPIWithCredentials.get<GetNoticesListResponse>('/app/notices', {
    params: {
      page: (params.page || 0) + 1,
      per_page: params.perPage || LIST_ITEMS_PER_PAGE,
      status: params.status,
    },
    ...config,
  })

export type CreateNoticeParams = {
  account_no: string
  bank_id: number
  border_number: string
  dob: string // 1998-11-17,
  documents?: { [key: string]: '1' }
  occupation: number
  relative_mobile: string
  relative_name: string
  relative_type: string
  worker_mobile: string
  worker_name_en: string
  worker_salary: string
}

export type CreateNoticeResponse = {
  notice: number
}

export type CreateNoticeFailResponse = {
  errors?: Record<string, Array<string>>
  error?: {
    message: UserEligibilityErrorMessages | string
    code: number
  }
}
const createNotice = (params: CreateNoticeParams, config: AxiosRequestConfig) =>
  evisaAPIWithCredentials.post<CreateNoticeResponse>('/app/notices', params, config)
type GetBanksListParams = {}

export type BankFromBE = ObjectWithTranslationFromBE

export type GetBanksListResponse = {
  banks: Array<BankFromBE>
}

export type GetBanksListFailResponse = {
  error: {
    message: string
    code: number
  }
}
const getBanksList = (params: GetBanksListParams, config: AxiosRequestConfig) =>
  evisaAPIWithCredentials.get<GetBanksListResponse>('/app/notices/getData', { params, ...config })

type GetAvailableOccupationsListParams = {}

export type CheckNoticeAvailabilityResponse = {
  yemeniOccupationsList: Array<OccupationFromBE>
  isFinanciallyCapable: boolean
  numberOfRemainingVisa: number
  totalNumberOfVisa: number
  isSaudi: boolean
}

export type CheckNoticeAvailabilityFailResponse = {
  error: {
    message: UserEligibilityErrorMessages
    code: number
  }
}
const checkNoticeAvailability = (params: GetAvailableOccupationsListParams, config: AxiosRequestConfig) =>
  evisaAPIWithCredentials.get<CheckNoticeAvailabilityResponse>('/app/notices/check_availability', {
    params,
    ...config,
  })

export type GetNoticeParams = {
  noticeId: number | string
}

export type GetNoticeResponse = {
  noticeRequest: NoticeFromBE
  show_current_balance: boolean
  show_current_balance_and_refund_request: boolean
}

const getNotice = (params: GetNoticeParams, config?: AxiosRequestConfig) =>
  evisaAPIWithCredentials.get<GetNoticeResponse>(`/app/notices/${params.noticeId}/show`, {
    params,
    ...config,
  })

export type PayNoticeParams = {
  noticeId: number
  paymentMethod: PaymentMethods
}

export type PayNoticeResponse = {
  status: boolean
  bill: PayfortBill
}

const payNotice = (params: PayNoticeParams, config: AxiosRequestConfig) =>
  evisaAPIWithCredentials.post<PayNoticeResponse>(
    `app/notices/${params.noticeId}/payfort_bill/${params.paymentMethod}`,
    {
      channel: 1,
    },
    config,
  )

export const noticesAPI = {
  getNoticesList: callAPI<typeof getNoticesList, GetNoticesListResponse>(getNoticesList),
  createNotice: callAPI<typeof createNotice, CreateNoticeResponse>(createNotice),
  getBanksList: callAPI<typeof getBanksList, GetBanksListResponse>(getBanksList),
  getAvailableOccupationsList: callAPI<
    typeof checkNoticeAvailability,
    CheckNoticeAvailabilityResponse,
    CheckNoticeAvailabilityFailResponse
  >(checkNoticeAvailability),
  getNotice: callAPI<typeof getNotice, GetNoticeResponse>(getNotice),
  payNotice: callAPI<typeof payNotice, PayNoticeResponse>(payNotice),
}

export const rawNoticesAPI = {
  getNoticesList,
  getNotice,
}
