import { CallAPIAction, Status } from 'api/types'
import Actions from 'redux/actions'
import { GetInterviewResponse } from 'api/interviewAPI'
import { LaborerInterview } from 'common/types/transformedRespTypes'
import { InterviewStatuses } from 'common/types/commonTypes'

import { convertResponseToState } from './interviewsList'

type State = {
  data: LaborerInterview
} & Status

const initialState = {
  data: {
    createdAt: '',
    id: '',
    employer: {
      email: '',
      id: '',
      mobile: '',
      name: '',
    },
    laborer: {
      ageGroup: '',
      mobile: '',
      name: '',
      occupation: {
        id: 0,
        label: '',
        labelEn: '',
      },
      nationality: {
        id: 0,
        label: '',
        labelEn: '',
        abbreviation: '',
        code: '',
        isOnlineContractingAllowed: false,
      },
      passport: '',
      passportExpiry: '',
      passportUpload: '',
    },
    pros: [],
    invitations: [],
    status: InterviewStatuses.INCOMPLETE,
    updatedAt: '',
  },
  isLoading: false,
  success: false,
  fail: false,
}

export default (
  state: State = initialState,
  action: CallAPIAction<
    Actions.GET_LABORER_INTERVIEW_ASYNC,
    Actions.GET_LABORER_INTERVIEW_SUCCESS,
    Actions.GET_LABORER_INTERVIEW_FAIL,
    GetInterviewResponse
  >,
): State => {
  switch (action.type) {
    case Actions.GET_LABORER_INTERVIEW_ASYNC:
    case Actions.GET_LABORER_INTERVIEW_FAIL: {
      return {
        ...state,
        ...action.status,
      }
    }
    case Actions.GET_LABORER_INTERVIEW_SUCCESS: {
      const request = [action.payload.data].map(convertResponseToState)

      return {
        ...state,
        ...action.status,
        data: request[0],
      }
    }

    default:
      return state
  }
}
