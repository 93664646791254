import { GetLaborerCVRequestResponse } from 'api/contractAuthAPI'
import { CallAPIAction, Status } from 'api/types'
import { ListingCVRequest } from 'common/types/commonTypes'
import { convertListingCVRequestToState, getEmptyListingCVRequest } from 'common/utils/listingRequestHelper'
import Actions from 'redux/actions'

type State = {
  data: ListingCVRequest
} & Status

const initialState: State = {
  data: getEmptyListingCVRequest(),
  isLoading: false,
  success: false,
  fail: false,
}

export default (
  state: State = initialState,
  action: CallAPIAction<
    Actions.GET_LISTING_LABORER_CV_REQUEST_ASYNC,
    Actions.GET_LISTING_LABORER_CV_REQUEST_SUCCESS,
    Actions.GET_LISTING_LABORER_CV_REQUEST_FAIL,
    GetLaborerCVRequestResponse
  >,
) => {
  switch (action.type) {
    case Actions.GET_LISTING_LABORER_CV_REQUEST_ASYNC:
    case Actions.GET_LISTING_LABORER_CV_REQUEST_FAIL: {
      return {
        ...state,
        ...action.status,
      }
    }
    case Actions.GET_LISTING_LABORER_CV_REQUEST_SUCCESS: {
      const request = [action.payload.data].map(convertListingCVRequestToState)

      return {
        ...state,
        ...action.status,
        data: request[0],
      }
    }
    default:
      return state
  }
}
