import { GetTransactionsListResponse } from 'api/laborerPortalAPI/wps'
import { CallAPIAction, Status } from 'api/types'
import { convertTransactionFromResponse, Transaction } from 'common/utils/wpsConvertResponse'
import Actions from 'redux/actions'

type State = {
  data: Array<Transaction>
} & Status

const initialState: State = {
  data: [],
  isLoading: false,
  success: false,
  fail: false,
}

export default (
  state: State = initialState,
  action: CallAPIAction<
    Actions.GET_OFFLINE_SALARY_LIST_ASYNC,
    Actions.GET_OFFLINE_SALARY_LIST_SUCCESS,
    Actions.GET_OFFLINE_SALARY_LIST_FAIL,
    GetTransactionsListResponse
  >,
) => {
  switch (action.type) {
    case Actions.GET_OFFLINE_SALARY_LIST_FAIL:
    case Actions.GET_OFFLINE_SALARY_LIST_ASYNC:
      return {
        ...state,
        ...action.status,
      }

    case Actions.GET_OFFLINE_SALARY_LIST_SUCCESS:
      return {
        ...state,
        ...action.status,
        data: action.payload.data.map(convertTransactionFromResponse),
      }
    default:
      return state
  }
}
