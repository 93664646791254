import { ListLaborerCVFailResponse, SubmitEditListingRequestResponse } from 'api/contractAuthAPI'
import { CallAPIAction, Status } from 'api/types'
import Actions from 'redux/actions'

type State = {
  errorMessage: string
  id: number
} & Status

const initialState: State = {
  errorMessage: '',
  id: 0,
  isLoading: false,
  success: false,
  fail: false,
}

export default (
  state: State = initialState,
  action: CallAPIAction<
    Actions.SUBMIT_EDIT_LISTING_REQUEST_ASYNC,
    Actions.SUBMIT_EDIT_LISTING_REQUEST_SUCCESS,
    Actions.SUBMIT_EDIT_LISTING_REQUEST_FAIL,
    SubmitEditListingRequestResponse,
    ListLaborerCVFailResponse
  >,
) => {
  switch (action.type) {
    case Actions.SUBMIT_EDIT_LISTING_REQUEST_ASYNC:
      return {
        ...state,
        ...action.status,
      }

    case Actions.SUBMIT_EDIT_LISTING_REQUEST_SUCCESS:
      return {
        ...state,
        ...action.status,
        id: action.payload.data.id,
      }

    case Actions.SUBMIT_EDIT_LISTING_REQUEST_FAIL:
      return {
        ...state,
        ...action.status,
        errorMessage: action.payload?.message ?? '',
      }
    default:
      return state
  }
}
