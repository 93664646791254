import { CallAPIAction, Status } from 'api/types'
import { PayfortBill } from 'common/types/transformedRespTypes'
import Actions from 'redux/actions'
import { PayForInterviewResponse, PayForInterviewFailResponse } from 'api/interviewAPI'

type State = {
  bill?: PayfortBill
  error: string
} & Status

const initialState = {
  error: '',
  isLoading: false,
  success: false,
  fail: false,
}

export default (
  state: State = initialState,
  action: CallAPIAction<
    Actions.PAY_FOR_INTERVIEW_ASYNC,
    Actions.PAY_FOR_INTERVIEW_SUCCESS,
    Actions.PAY_FOR_INTERVIEW_FAIL,
    PayForInterviewResponse,
    PayForInterviewFailResponse
  >,
): State => {
  switch (action.type) {
    case Actions.PAY_FOR_INTERVIEW_SUCCESS: {
      return {
        ...state,
        ...action.status,
        bill: {
          params: action.payload.data.tokenization_data.params,
          url: action.payload.data.tokenization_data.url,
        },
      }
    }
    case Actions.PAY_FOR_INTERVIEW_ASYNC: {
      return {
        ...state,
        ...action.status,
      }
    }

    case Actions.PAY_FOR_INTERVIEW_FAIL: {
      return {
        ...state,
        ...action.status,
        error: action.payload?.error || '',
      }
    }

    default:
      return state
  }
}
