import { takeLatest } from 'redux-saga/effects'

import {
  getTransactionsList as getTransactionsListAction,
  getTransactionDetails as getTransactionDetailsAction,
  acceptSalaryPayment as acceptSalaryPaymentAction,
  rejectSalaryPayment as rejectSalaryPaymentAction,
} from 'redux/actionCreators/laborerPortalAPI/wps'
import laborerPortalAPI from 'api/laborerPortalAPI/wps'
import Actions from 'redux/actions'
import { getSimpleRequestHandler } from 'saga/utils'

const getLaborerTransfersList = getSimpleRequestHandler<
  typeof laborerPortalAPI.getTransactionsList,
  ReturnType<typeof getTransactionsListAction>
>({
  actionLoading: Actions.GET_OFFLINE_SALARY_LIST_ASYNC,
  actionSuccess: Actions.GET_OFFLINE_SALARY_LIST_SUCCESS,
  actionFail: Actions.GET_OFFLINE_SALARY_LIST_FAIL,
  callApiFn: laborerPortalAPI.getTransactionsList,
})

const getTransactionDetails = getSimpleRequestHandler<
  typeof laborerPortalAPI.getTransactionDetails,
  ReturnType<typeof getTransactionDetailsAction>
>({
  actionLoading: Actions.GET_OFFLINE_SALARY_DETAILS_ASYNC,
  actionSuccess: Actions.GET_OFFLINE_SALARY_DETAILS_SUCCESS,
  actionFail: Actions.GET_OFFLINE_SALARY_DETAILS_FAIL,
  callApiFn: laborerPortalAPI.getTransactionDetails,
})

const acceptSalaryPayment = getSimpleRequestHandler<
  typeof laborerPortalAPI.acceptSalaryPayment,
  ReturnType<typeof acceptSalaryPaymentAction>
>({
  actionLoading: Actions.ACCEPT_SALARY_PAYMENT_ASYNC,
  actionSuccess: Actions.ACCEPT_SALARY_PAYMENT_SUCCESS,
  actionFail: Actions.ACCEPT_SALARY_PAYMENT_FAIL,
  callApiFn: laborerPortalAPI.acceptSalaryPayment,
})

const rejectSalaryPayment = getSimpleRequestHandler<
  typeof laborerPortalAPI.rejectSalaryPayment,
  ReturnType<typeof rejectSalaryPaymentAction>
>({
  actionLoading: Actions.REJECT_SALARY_PAYMENT_ASYNC,
  actionSuccess: Actions.REJECT_SALARY_PAYMENT_SUCCESS,
  actionFail: Actions.REJECT_SALARY_PAYMENT_FAIL,
  callApiFn: laborerPortalAPI.rejectSalaryPayment,
})

export default [
  takeLatest(Actions.GET_OFFLINE_SALARY_LIST, getLaborerTransfersList),
  takeLatest(Actions.GET_OFFLINE_SALARY_DETAILS, getTransactionDetails),
  takeLatest(Actions.ACCEPT_SALARY_PAYMENT, acceptSalaryPayment),
  takeLatest(Actions.REJECT_SALARY_PAYMENT, rejectSalaryPayment),
]
