import Actions from 'redux/actions'
import type { CallAPIAction, Status } from 'api/types'
import { SelectListingCVFailResponse, SelectListingCVResponse } from 'api/contractAuthAPI'

type State = {
  error: string
} & Status

const initialState = {
  error: '',
  isLoading: false,
  success: false,
  fail: false,
}

export default (
  state: State = initialState,
  action: CallAPIAction<
    Actions.SELECT_LISTING_CV_ASYNC,
    Actions.SELECT_LISTING_CV_SUCCESS,
    Actions.SELECT_LISTING_CV_FAIL,
    SelectListingCVResponse,
    SelectListingCVFailResponse
  >,
): State => {
  switch (action.type) {
    case Actions.SELECT_LISTING_CV_ASYNC:
    case Actions.SELECT_LISTING_CV_SUCCESS: {
      return {
        ...state,
        ...action.status,
      }
    }

    case Actions.SELECT_LISTING_CV_FAIL: {
      return {
        ...state,
        ...action.status,
        error: action.payload?.message || '',
      }
    }
    default:
      return state
  }
}
