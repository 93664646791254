import { GetTransactionDetailsResponse } from 'api/laborerPortalAPI/wps'
import { CallAPIAction, Status } from 'api/types'
import { convertTransactionFromResponse, getEmptyTransaction, Transaction } from 'common/utils/wpsConvertResponse'
import Actions from 'redux/actions'

type State = {
  data: Transaction
} & Status

const initialState: State = {
  data: getEmptyTransaction(),
  isLoading: false,
  success: false,
  fail: false,
}

export default (
  state: State = initialState,
  action: CallAPIAction<
    Actions.GET_OFFLINE_SALARY_DETAILS_ASYNC,
    Actions.GET_OFFLINE_SALARY_DETAILS_SUCCESS,
    Actions.GET_OFFLINE_SALARY_DETAILS_FAIL,
    GetTransactionDetailsResponse
  >,
) => {
  switch (action.type) {
    case Actions.GET_OFFLINE_SALARY_DETAILS_FAIL:
    case Actions.GET_OFFLINE_SALARY_DETAILS_ASYNC:
      return {
        ...state,
        ...action.status,
      }
    case Actions.GET_OFFLINE_SALARY_DETAILS_SUCCESS: {
      const request = [action.payload.data].map(convertTransactionFromResponse)

      return {
        ...state,
        ...action.status,
        data: request[0],
      }
    }

    default:
      return state
  }
}
